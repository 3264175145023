import React from 'react';
import { Link } from 'react-router-dom';

import './Logo.css';

function Logo() {
	return (
		<div className="logo-container">
			<Link to="/">
				<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 141.52">
					<path className="cls-2" d="m132.9,28.59c.26-1.27.47-2.74.62-4.39,3.82-1.05,7.14-1.77,9.95-2.16,2.81-.39,5.66-.59,8.55-.59,3.34,0,6.44.29,9.31.87,2.87.58,5.21,1.56,7.03,2.92,1.82,1.37,3.02,2.93,3.6,4.7.58,1.76.87,3.56.87,5.4s-.07,4.25-.2,7.11c-.13,2.87-.31,5.95-.53,9.25-.22,3.3-.43,7.41-.62,12.34-.19,4.93-.28,8.48-.28,10.66v4.72c-.98-.04-1.76-.06-2.37-.06-.64,0-1.43.02-2.37.06.52-5.74.89-10.38,1.09-13.95.21-3.56.37-7.4.51-11.5.13-4.11.31-7.94.53-11.5-1.16.97-2.05,1.69-2.67,2.16-.62.47-1.29.89-2.02,1.26s-1.61.76-2.64,1.15c-1.03.39-1.98.73-2.84,1.01-.86.28-2.95.87-6.27,1.77-3.32.9-5.69,1.57-7.11,2.02-3.22,1.05-5.72,2.04-7.48,2.98-1.91,1.01-3.5,2.13-4.78,3.37-1.12,1.09-1.95,2.23-2.47,3.43-.6,1.42-.9,2.94-.9,4.55,0,2.43.73,4.55,2.19,6.34,1.46,1.8,3.31,3.06,5.54,3.79,2.23.73,4.92,1.09,8.07,1.09,1.57,0,3.09-.08,4.56-.25,1.46-.17,2.84-.39,4.13-.67,1.29-.28,3.16-.82,5.6-1.6-.34,1.43-.62,2.85-.84,4.27-3.04.6-5.5.98-7.39,1.15-1.89.17-3.68.25-5.37.25-3.15,0-6.05-.24-8.72-.73-2.66-.49-4.89-1.44-6.69-2.86s-3.13-3.07-3.99-4.94c-.86-1.87-1.29-3.84-1.29-5.9,0-1.57.26-3.1.79-4.58.52-1.48,1.33-2.89,2.42-4.24.9-1.12,2.1-2.17,3.6-3.14,1.76-1.2,3.71-2.21,5.85-3.03,2.47-.97,5.13-1.87,7.98-2.7.79-.22,3.88-1.03,9.27-2.42,2.44-.64,4.74-1.43,6.91-2.39,2.17-.95,3.86-2.28,5.06-3.99,1.2-1.7,1.8-3.44,1.8-5.2,0-1.27-.28-2.5-.84-3.68-.56-1.18-1.56-2.3-3.01-3.35-1.44-1.05-3.23-1.78-5.37-2.19-2.14-.41-4.38-.62-6.74-.62-2.74,0-5.3.18-7.7.53-2.4.36-4.42.77-6.07,1.24-1.65.47-3.73,1.21-6.24,2.22Z" />
					<path className="cls-2" d="m176.54,78.3c-.08-1.8-.22-3.39-.45-4.78,2.96,1.31,5.75,2.23,8.38,2.76,2.62.53,5.02.79,7.2.79,2.89,0,5.35-.41,7.39-1.24,2.04-.82,3.59-2.04,4.64-3.65,1.05-1.61,1.57-3.3,1.57-5.06,0-.79-.08-1.54-.25-2.28s-.44-1.46-.82-2.19c-.38-.73-.8-1.4-1.27-2.02-.47-.62-1.12-1.32-1.97-2.11s-1.83-1.67-2.95-2.64c-1.12-.97-2.89-2.45-5.29-4.44-2.4-1.99-4.38-3.7-5.93-5.14-1.56-1.44-2.85-2.9-3.88-4.38-1.03-1.48-1.76-2.84-2.19-4.07-.43-1.24-.65-2.49-.65-3.76,0-2.17.68-4.28,2.05-6.32,1.37-2.04,3.34-3.61,5.9-4.69,2.57-1.09,5.82-1.63,9.76-1.63,3.3,0,7.01.43,11.13,1.29.04,1.65.15,3.24.34,4.78-2.4-1.01-4.55-1.7-6.44-2.05-1.89-.36-3.82-.53-5.76-.53-2.44,0-4.69.37-6.75,1.1-2.06.73-3.61,1.8-4.64,3.2-1.03,1.4-1.55,2.93-1.55,4.58,0,.9.11,1.74.34,2.53.22.79.58,1.58,1.07,2.39.49.8,1.12,1.63,1.88,2.47.77.84,1.76,1.82,2.98,2.92,1.22,1.11,2.98,2.62,5.29,4.55,2.31,1.93,4.02,3.39,5.15,4.38s2.09,1.9,2.9,2.73c.81.82,1.57,1.73,2.31,2.72s1.32,1.96,1.77,2.89c.45.94.79,1.88,1.01,2.84.22.95.34,1.92.34,2.89,0,2.36-.69,4.59-2.08,6.69-1.39,2.1-3.4,3.74-6.05,4.94-2.64,1.2-5.82,1.8-9.53,1.8-1.39,0-3.12-.11-5.2-.34s-3.75-.46-5-.7c-1.26-.24-2.84-.65-4.75-1.21Z" />
					<path className="cls-2" d="m211.02,78.3c-.08-1.8-.22-3.39-.45-4.78,2.96,1.31,5.75,2.23,8.38,2.76,2.62.53,5.02.79,7.2.79,2.89,0,5.35-.41,7.39-1.24,2.04-.82,3.59-2.04,4.64-3.65,1.05-1.61,1.57-3.3,1.57-5.06,0-.79-.08-1.54-.25-2.28-.17-.73-.44-1.46-.82-2.19-.38-.73-.8-1.4-1.26-2.02-.47-.62-1.12-1.32-1.97-2.11-.84-.79-1.83-1.67-2.95-2.64-1.12-.97-2.89-2.45-5.29-4.44-2.4-1.99-4.38-3.7-5.93-5.14-1.56-1.44-2.85-2.9-3.88-4.38-1.03-1.48-1.76-2.84-2.19-4.07-.43-1.24-.65-2.49-.65-3.76,0-2.17.68-4.28,2.05-6.32,1.37-2.04,3.34-3.61,5.9-4.69,2.57-1.09,5.82-1.63,9.76-1.63,3.3,0,7.01.43,11.13,1.29.04,1.65.15,3.24.34,4.78-2.4-1.01-4.55-1.7-6.44-2.05-1.89-.36-3.82-.53-5.76-.53-2.44,0-4.69.37-6.75,1.1-2.06.73-3.61,1.8-4.64,3.2-1.03,1.4-1.55,2.93-1.55,4.58,0,.9.11,1.74.34,2.53.22.79.58,1.58,1.07,2.39.49.8,1.12,1.63,1.88,2.47.77.84,1.76,1.82,2.98,2.92,1.22,1.11,2.98,2.62,5.29,4.55,2.31,1.93,4.02,3.39,5.15,4.38s2.09,1.9,2.9,2.73c.81.82,1.57,1.73,2.3,2.72.73.99,1.32,1.96,1.77,2.89.45.94.79,1.88,1.01,2.84.22.95.34,1.92.34,2.89,0,2.36-.69,4.59-2.08,6.69-1.39,2.1-3.4,3.74-6.04,4.94-2.64,1.2-5.82,1.8-9.53,1.8-1.39,0-3.12-.11-5.2-.34s-3.75-.46-5-.7c-1.26-.24-2.84-.65-4.75-1.21Z" />
					<path className="cls-2" d="m249.66,79.43c.42-5.62.91-15.43,1.47-29.41.56-13.98.83-23.11.83-27.39.96.04,1.7.06,2.22.06.56,0,1.32-.02,2.28-.06-.52,7.12-1.04,16.83-1.54,29.13-.51,12.3-.76,21.52-.76,27.67-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m260.07,78.3c-.08-1.8-.22-3.39-.45-4.78,2.96,1.31,5.75,2.23,8.38,2.76,2.62.53,5.02.79,7.2.79,2.89,0,5.35-.41,7.39-1.24,2.04-.82,3.59-2.04,4.64-3.65,1.05-1.61,1.57-3.3,1.57-5.06,0-.79-.08-1.54-.25-2.28-.17-.73-.44-1.46-.82-2.19-.38-.73-.8-1.4-1.26-2.02-.47-.62-1.12-1.32-1.97-2.11-.84-.79-1.83-1.67-2.95-2.64-1.12-.97-2.89-2.45-5.29-4.44-2.4-1.99-4.38-3.7-5.93-5.14-1.56-1.44-2.85-2.9-3.88-4.38-1.03-1.48-1.76-2.84-2.19-4.07-.43-1.24-.65-2.49-.65-3.76,0-2.17.68-4.28,2.05-6.32,1.37-2.04,3.34-3.61,5.9-4.69,2.57-1.09,5.82-1.63,9.76-1.63,3.3,0,7.01.43,11.13,1.29.04,1.65.15,3.24.34,4.78-2.4-1.01-4.55-1.7-6.44-2.05-1.89-.36-3.82-.53-5.76-.53-2.44,0-4.69.37-6.75,1.1-2.06.73-3.61,1.8-4.64,3.2-1.03,1.4-1.55,2.93-1.55,4.58,0,.9.11,1.74.34,2.53.22.79.58,1.58,1.07,2.39.49.8,1.11,1.63,1.88,2.47.77.84,1.76,1.82,2.98,2.92,1.22,1.11,2.98,2.62,5.29,4.55,2.31,1.93,4.02,3.39,5.15,4.38s2.09,1.9,2.9,2.73c.81.82,1.57,1.73,2.3,2.72.73.99,1.32,1.96,1.77,2.89.45.94.79,1.88,1.01,2.84.22.95.34,1.92.34,2.89,0,2.36-.69,4.59-2.08,6.69-1.39,2.1-3.4,3.74-6.04,4.94-2.64,1.2-5.82,1.8-9.53,1.8-1.39,0-3.12-.11-5.2-.34-2.08-.22-3.75-.46-5.01-.7-1.26-.24-2.84-.65-4.75-1.21Z" />
					<path className="cls-2" d="m321.15,75.88c-.3,1.12-.53,2.31-.67,3.54-3.04.75-5.83,1.12-8.38,1.12-2.14,0-4.04-.23-5.71-.7-1.67-.47-3.03-1.31-4.08-2.53-1.05-1.22-1.74-2.57-2.08-4.05-.34-1.48-.51-3.06-.51-4.75,0-.3,0-.77.03-1.41.02-.64.09-2.04.2-4.22l1.88-37.23h-6.94c.15-1.01.24-2.02.28-3.04h6.87c.19-2.36.35-5.89.46-10.57,1.42-.19,2.65-.43,3.69-.73-.26,6.07-.4,9.84-.4,11.3h16.87c-.15,1.01-.24,2.02-.28,3.04h-16.78l-1.84,38.54-.06,2.3c0,3.15.37,5.43,1.1,6.85.73,1.42,1.76,2.45,3.09,3.09,1.33.64,2.71.96,4.14.96,1.01,0,2.09-.08,3.24-.24s3.1-.59,5.88-1.27Z" />
					<path className="cls-2" d="m365.59,73.86c-.3,1.41-.53,2.83-.67,4.27-3.11.9-5.9,1.53-8.38,1.88-2.47.36-5,.53-7.59.53-4.5,0-8.48-.61-11.95-1.83-3.47-1.22-6.44-3.24-8.91-6.07-2.47-2.83-4.24-6-5.29-9.52-1.05-3.52-1.57-7.27-1.57-11.24,0-5.21.9-10.08,2.7-14.64s4.76-8.32,8.89-11.32c4.12-3,9.24-4.49,15.35-4.49,4.2,0,8,.78,11.42,2.33,3.41,1.56,6.06,4.1,7.96,7.62,1.89,3.52,2.84,7.7,2.84,12.54,0,.9-.04,1.76-.11,2.59l-44.54.06c-.19,2.43-.28,4.27-.28,5.5,0,3.56.53,7,1.58,10.33,1.05,3.33,2.64,6.12,4.76,8.36s4.66,3.91,7.63,5c2.97,1.09,6.1,1.63,9.4,1.63,5.03,0,10.62-1.18,16.78-3.54Zm-39.36-30.37l40.04.06c-.04-2.59-.19-4.57-.45-5.96-.26-1.39-.75-2.81-1.46-4.27s-1.57-2.73-2.56-3.8c-1-1.07-2.16-1.97-3.49-2.7-1.33-.73-2.82-1.28-4.48-1.66-1.65-.37-3.38-.56-5.18-.56-1.99,0-3.94.22-5.86.67s-3.66,1.07-5.24,1.86c-1.58.79-3.05,1.79-4.42,3.01-1.37,1.22-2.5,2.54-3.38,3.96-.88,1.43-1.56,2.78-2.03,4.08-.47,1.29-.97,3.07-1.49,5.31Z" />
					<path className="cls-2" d="m422.84,79.48c-.99-.04-1.73-.06-2.22-.06h-2.28l.82-14.32c-.9,2.17-1.73,3.89-2.5,5.17s-1.74,2.52-2.92,3.74c-1.18,1.22-2.61,2.31-4.3,3.29-1.69.97-3.63,1.76-5.85,2.36-2.21.6-4.5.9-6.86.9-3.63,0-7.03-.66-10.2-1.97-3.17-1.31-5.85-3.17-8.04-5.59-2.19-2.42-3.77-5.21-4.72-8.37-.96-3.17-1.43-6.51-1.43-10.03,0-4.76.88-9.34,2.64-13.76,1.76-4.42,4.32-8.14,7.68-11.15,3.35-3.01,7.16-5.14,11.41-6.38,4.25-1.24,8.74-1.85,13.47-1.85,3.86,0,8.08.41,12.65,1.24.19-3.56.29-6.97.29-10.23,0-2.89-.06-6.15-.17-9.78.98.04,1.76.06,2.36.06s1.37-.02,2.31-.06c-.42,7.02-.74,14.37-.97,22.06l-.8,27.24c-.25,10.43-.37,17.3-.37,20.6v6.92Zm-2.81-52.92c-2.66-.82-4.97-1.36-6.92-1.6-1.95-.24-3.81-.37-5.57-.37-4.2,0-8.21.6-12.04,1.8-3.82,1.2-7.16,3.15-10.01,5.84-2.85,2.7-5.05,6.11-6.61,10.22-1.56,4.12-2.33,8.18-2.33,12.19,0,4.42.85,8.45,2.56,12.08,1.7,3.63,4.12,6.32,7.25,8.06,3.13,1.74,6.62,2.61,10.48,2.61,2.29,0,4.37-.27,6.27-.81,1.89-.54,3.6-1.3,5.11-2.28,1.52-.97,2.96-2.27,4.33-3.9,1.37-1.63,2.57-3.55,3.6-5.76,1.03-2.21,1.79-4.62,2.28-7.25.49-2.62.82-6.65,1.01-12.08l.59-18.76Z" />
					<path className="cls-2" d="m130.2,140.4c.42-5.62.91-15.43,1.47-29.41.56-13.98.83-23.11.83-27.39.96.04,1.7.06,2.22.06.56,0,1.32-.02,2.28-.06-.52,7.12-1.04,16.83-1.54,29.13-.51,12.3-.76,21.52-.76,27.67-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m143.98,140.4l1.91-34.36.51-16.14c.07-2.89.11-4.99.11-6.3.98.04,1.71.06,2.2.06.45,0,1.16-.02,2.14-.06l-.76,12.82c.83-1.76,1.51-3.1,2.05-4.02.54-.92,1.28-1.9,2.22-2.95.94-1.05,2.05-2.03,3.35-2.95,1.29-.92,2.85-1.69,4.67-2.31,1.82-.62,3.69-1.07,5.6-1.35,1.91-.28,3.81-.42,5.68-.42,3.15,0,6.21.36,9.17,1.07,2.96.71,5.42,1.94,7.37,3.68,1.95,1.74,3.29,3.75,4.02,6.02.73,2.27,1.1,4.7,1.1,7.28,0,1.16-.06,2.89-.17,5.17l-1.18,25.59-.22,9.17c-.75-.04-1.48-.06-2.2-.06s-1.43.02-2.14.06c.37-4.69.67-9.17.89-13.44l.78-14.4c.3-5.32.44-9.17.44-11.53s-.36-4.69-1.07-6.64c-.71-1.95-1.94-3.57-3.68-4.86-1.74-1.29-3.81-2.23-6.21-2.81-2.4-.58-4.87-.87-7.42-.87-3.56,0-6.71.49-9.44,1.46-2.59.94-4.85,2.32-6.8,4.13-1.95,1.82-3.54,4.11-4.78,6.89-.94,2.02-1.65,4.86-2.14,8.49-.45,3.15-.81,8.29-1.09,15.44-.28,7.14-.42,13.19-.42,18.14-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m202.7,140.4l1.91-34.36.51-16.14c.07-2.89.11-4.99.11-6.3.98.04,1.71.06,2.2.06.45,0,1.16-.02,2.14-.06l-.76,12.82c.83-1.76,1.51-3.1,2.05-4.02.54-.92,1.28-1.9,2.22-2.95.94-1.05,2.05-2.03,3.35-2.95,1.29-.92,2.85-1.69,4.67-2.31,1.82-.62,3.69-1.07,5.6-1.35,1.91-.28,3.81-.42,5.68-.42,3.15,0,6.21.36,9.17,1.07,2.96.71,5.42,1.94,7.37,3.68,1.95,1.74,3.29,3.75,4.02,6.02.73,2.27,1.1,4.7,1.1,7.28,0,1.16-.06,2.89-.17,5.17l-1.18,25.59-.22,9.17c-.75-.04-1.48-.06-2.2-.06s-1.43.02-2.14.06c.37-4.69.67-9.17.89-13.44l.78-14.4c.3-5.32.44-9.17.44-11.53s-.36-4.69-1.07-6.64c-.71-1.95-1.94-3.57-3.68-4.86-1.74-1.29-3.81-2.23-6.21-2.81-2.4-.58-4.87-.87-7.42-.87-3.56,0-6.71.49-9.44,1.46-2.59.94-4.85,2.32-6.8,4.13-1.95,1.82-3.54,4.11-4.78,6.89-.94,2.02-1.65,4.86-2.14,8.49-.45,3.15-.81,8.29-1.09,15.44-.28,7.14-.42,13.19-.42,18.14-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m287.79,82.42c3.49,0,6.86.49,10.12,1.46,3.26.98,6.14,2.63,8.63,4.98,2.49,2.34,4.4,5.41,5.71,9.19,1.31,3.79,1.97,7.8,1.97,12.03,0,4.57-.69,8.96-2.08,13.16-1.39,4.2-3.36,7.71-5.9,10.54-2.55,2.83-5.54,4.83-8.97,5.99-3.43,1.16-7.08,1.74-10.94,1.74-2.7,0-5.35-.31-7.96-.93-2.61-.62-5.04-1.66-7.31-3.12-2.27-1.46-4.26-3.35-5.96-5.68-1.71-2.32-3-5.08-3.88-8.27-.88-3.19-1.32-6.5-1.32-9.95,0-6.04,1.12-11.56,3.37-16.56,2.25-5,5.61-8.69,10.09-11.05,4.48-2.36,9.29-3.54,14.42-3.54Zm-.11,3.15c-4.46,0-8.6,1.09-12.43,3.26s-6.64,5.49-8.44,9.94c-1.8,4.46-2.7,9.34-2.7,14.66,0,3.74.51,7.27,1.55,10.56,1.03,3.3,2.69,6.08,4.98,8.34,2.29,2.27,4.75,3.84,7.4,4.72,2.64.88,5.43,1.32,8.35,1.32,3.34,0,6.47-.54,9.39-1.63,2.92-1.09,5.5-2.9,7.73-5.45,2.23-2.55,3.87-5.67,4.92-9.38,1.05-3.71,1.57-7.64,1.57-11.8,0-3.86-.52-7.48-1.55-10.87-1.03-3.39-2.63-6.1-4.81-8.14-2.17-2.04-4.65-3.47-7.42-4.3-2.77-.82-5.62-1.24-8.55-1.24Z" />
					<path className="cls-2" d="m334.55,140.4c-2.1-6.11-4.56-12.91-7.38-20.41l-13.62-36.38c.97.04,1.81.06,2.52.06.75,0,1.59-.02,2.52-.06l1.46,4.78c.3.9.62,1.8.96,2.7l16.5,46.12,21.2-48.82,1.86-4.78c.97.04,1.82.06,2.53.06.75,0,1.59-.02,2.53-.06l-22.64,49.6-3.03,7.2c-.94-.04-1.84-.06-2.7-.06s-1.76.02-2.7.06Z" />
					<path className="cls-2" d="m367.66,89.56c.26-1.27.47-2.74.62-4.39,3.82-1.05,7.14-1.77,9.95-2.16,2.81-.39,5.66-.59,8.55-.59,3.34,0,6.44.29,9.31.87,2.87.58,5.21,1.56,7.03,2.92,1.82,1.37,3.02,2.93,3.6,4.7.58,1.76.87,3.56.87,5.4s-.07,4.25-.2,7.11c-.13,2.87-.31,5.95-.53,9.25-.22,3.3-.43,7.41-.62,12.34-.19,4.93-.28,8.48-.28,10.66v4.72c-.98-.04-1.76-.06-2.37-.06-.64,0-1.43.02-2.37.06.52-5.74.89-10.38,1.09-13.95.21-3.56.37-7.4.51-11.5.13-4.11.31-7.94.53-11.5-1.16.97-2.05,1.69-2.67,2.16-.62.47-1.29.89-2.02,1.26s-1.61.76-2.64,1.15c-1.03.39-1.98.73-2.84,1.01-.86.28-2.95.87-6.27,1.77-3.32.9-5.69,1.57-7.11,2.02-3.23,1.05-5.72,2.04-7.48,2.98-1.91,1.01-3.51,2.13-4.78,3.37-1.12,1.09-1.95,2.23-2.47,3.43-.6,1.42-.9,2.94-.9,4.55,0,2.43.73,4.55,2.19,6.34,1.46,1.8,3.31,3.06,5.54,3.79,2.23.73,4.92,1.09,8.07,1.09,1.57,0,3.09-.08,4.56-.25,1.46-.17,2.84-.39,4.13-.67,1.29-.28,3.16-.82,5.6-1.6-.34,1.43-.62,2.85-.84,4.27-3.04.6-5.5.98-7.4,1.15-1.89.17-3.68.25-5.37.25-3.15,0-6.05-.24-8.72-.73-2.66-.49-4.89-1.44-6.69-2.86s-3.13-3.07-3.99-4.94c-.86-1.87-1.29-3.84-1.29-5.9,0-1.57.26-3.1.79-4.58.52-1.48,1.33-2.89,2.42-4.24.9-1.12,2.1-2.17,3.6-3.14,1.76-1.2,3.71-2.21,5.85-3.03,2.47-.97,5.13-1.87,7.98-2.7.79-.22,3.88-1.03,9.27-2.42,2.44-.64,4.74-1.43,6.91-2.39,2.17-.95,3.86-2.28,5.06-3.99,1.2-1.7,1.8-3.44,1.8-5.2,0-1.27-.28-2.5-.84-3.68-.56-1.18-1.56-2.3-3.01-3.35-1.44-1.05-3.23-1.78-5.37-2.19-2.14-.41-4.38-.62-6.74-.62-2.74,0-5.3.18-7.7.53-2.4.36-4.42.77-6.07,1.24-1.65.47-3.73,1.21-6.24,2.22Z" />
					<path className="cls-2" d="m437.9,136.86c-.3,1.12-.53,2.31-.67,3.54-3.04.75-5.83,1.12-8.38,1.12-2.14,0-4.04-.23-5.71-.7-1.67-.47-3.03-1.31-4.08-2.53-1.05-1.22-1.74-2.57-2.08-4.05-.34-1.48-.51-3.06-.51-4.75,0-.3,0-.77.03-1.41.02-.64.09-2.04.2-4.22l1.88-37.23h-6.94c.15-1.01.24-2.02.28-3.04h6.87c.19-2.36.35-5.89.46-10.57,1.42-.19,2.65-.43,3.69-.73-.26,6.07-.4,9.84-.4,11.3h16.87c-.15,1.01-.24,2.02-.28,3.04h-16.78l-1.84,38.54-.06,2.3c0,3.15.37,5.43,1.1,6.85.73,1.42,1.76,2.45,3.09,3.09,1.33.64,2.71.96,4.14.96,1.01,0,2.09-.08,3.24-.24s3.1-.59,5.88-1.27Z" />
					<path className="cls-2" d="m440.27,140.4c.42-5.62.91-15.43,1.47-29.41.56-13.98.83-23.11.83-27.39.96.04,1.7.06,2.22.06.56,0,1.32-.02,2.28-.06-.52,7.12-1.04,16.83-1.54,29.13-.51,12.3-.76,21.52-.76,27.67-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m480.43,82.42c3.49,0,6.86.49,10.12,1.46,3.26.98,6.14,2.63,8.63,4.98,2.49,2.34,4.4,5.41,5.71,9.19,1.31,3.79,1.97,7.8,1.97,12.03,0,4.57-.69,8.96-2.08,13.16-1.39,4.2-3.36,7.71-5.9,10.54-2.55,2.83-5.54,4.83-8.97,5.99-3.43,1.16-7.08,1.74-10.94,1.74-2.7,0-5.35-.31-7.96-.93-2.61-.62-5.04-1.66-7.31-3.12-2.27-1.46-4.26-3.35-5.96-5.68-1.71-2.32-3-5.08-3.88-8.27-.88-3.19-1.32-6.5-1.32-9.95,0-6.04,1.12-11.56,3.37-16.56,2.25-5,5.61-8.69,10.09-11.05,4.48-2.36,9.29-3.54,14.42-3.54Zm-.11,3.15c-4.46,0-8.6,1.09-12.43,3.26s-6.64,5.49-8.44,9.94c-1.8,4.46-2.7,9.34-2.7,14.66,0,3.74.51,7.27,1.55,10.56,1.03,3.3,2.69,6.08,4.98,8.34,2.29,2.27,4.75,3.84,7.4,4.72,2.64.88,5.43,1.32,8.35,1.32,3.34,0,6.47-.54,9.39-1.63,2.92-1.09,5.5-2.9,7.73-5.45,2.23-2.55,3.87-5.67,4.92-9.38,1.05-3.71,1.57-7.64,1.57-11.8,0-3.86-.52-7.48-1.55-10.87-1.03-3.39-2.63-6.1-4.81-8.14-2.17-2.04-4.65-3.47-7.42-4.3-2.77-.82-5.62-1.24-8.55-1.24Z" />
					<path className="cls-2" d="m511.93,140.4l1.91-34.36.51-16.14c.07-2.89.11-4.99.11-6.3.98.04,1.71.06,2.19.06.45,0,1.16-.02,2.14-.06l-.76,12.82c.83-1.76,1.51-3.1,2.05-4.02.54-.92,1.28-1.9,2.22-2.95.94-1.05,2.05-2.03,3.35-2.95,1.29-.92,2.85-1.69,4.67-2.31,1.82-.62,3.69-1.07,5.6-1.35,1.91-.28,3.81-.42,5.68-.42,3.15,0,6.21.36,9.17,1.07,2.96.71,5.42,1.94,7.37,3.68,1.95,1.74,3.29,3.75,4.02,6.02.73,2.27,1.1,4.7,1.1,7.28,0,1.16-.06,2.89-.17,5.17l-1.18,25.59-.22,9.17c-.75-.04-1.48-.06-2.2-.06s-1.43.02-2.14.06c.37-4.69.67-9.17.89-13.44l.78-14.4c.3-5.32.44-9.17.44-11.53s-.36-4.69-1.07-6.64c-.71-1.95-1.94-3.57-3.68-4.86-1.74-1.29-3.81-2.23-6.21-2.81-2.4-.58-4.87-.87-7.42-.87-3.56,0-6.71.49-9.44,1.46-2.59.94-4.85,2.32-6.8,4.13-1.95,1.82-3.54,4.11-4.78,6.89-.94,2.02-1.65,4.86-2.14,8.49-.45,3.15-.81,8.29-1.09,15.44-.28,7.14-.42,13.19-.42,18.14-.98-.04-1.73-.06-2.25-.06s-1.28.02-2.25.06Z" />
					<path className="cls-2" d="m567.27,139.27c-.08-1.8-.22-3.39-.45-4.78,2.96,1.31,5.75,2.23,8.38,2.76,2.62.53,5.02.79,7.2.79,2.89,0,5.35-.41,7.39-1.24,2.04-.82,3.59-2.04,4.64-3.65,1.05-1.61,1.57-3.3,1.57-5.06,0-.79-.08-1.54-.25-2.28-.17-.73-.44-1.46-.82-2.19-.38-.73-.8-1.4-1.26-2.02-.47-.62-1.12-1.32-1.97-2.11-.84-.79-1.83-1.67-2.95-2.64-1.12-.97-2.89-2.45-5.29-4.44-2.4-1.99-4.38-3.7-5.93-5.14-1.56-1.44-2.85-2.9-3.88-4.38-1.03-1.48-1.76-2.84-2.19-4.07-.43-1.24-.65-2.49-.65-3.76,0-2.17.68-4.28,2.05-6.32,1.37-2.04,3.34-3.61,5.9-4.69,2.57-1.09,5.82-1.63,9.76-1.63,3.3,0,7.01.43,11.13,1.29.04,1.65.15,3.24.34,4.78-2.4-1.01-4.55-1.7-6.44-2.05-1.89-.36-3.82-.53-5.76-.53-2.44,0-4.69.37-6.75,1.1-2.06.73-3.61,1.8-4.64,3.2-1.03,1.4-1.55,2.93-1.55,4.58,0,.9.11,1.74.34,2.53.22.79.58,1.58,1.07,2.39.49.8,1.11,1.63,1.88,2.47.77.84,1.76,1.82,2.98,2.92,1.22,1.11,2.98,2.62,5.29,4.55,2.31,1.93,4.02,3.39,5.15,4.38s2.09,1.9,2.9,2.73c.81.82,1.57,1.73,2.3,2.72.73.99,1.32,1.96,1.77,2.89.45.94.79,1.88,1.01,2.84.22.95.34,1.92.34,2.89,0,2.36-.69,4.59-2.08,6.69-1.39,2.1-3.4,3.74-6.04,4.94-2.64,1.2-5.82,1.8-9.53,1.8-1.39,0-3.12-.11-5.2-.34-2.08-.22-3.75-.46-5.01-.7-1.26-.24-2.84-.65-4.75-1.21Z" />
					<circle className="cls-3" cx="71.32" cy="126.13" r="14.26" />
					<circle className="cls-1" cx="75.57" cy="39.16" r="13.03" />
					<circle className="cls-2" cx="102.55" cy="67.14" r="11.33" />
					<circle className="cls-3" cx="39.2" cy="29.45" r="12.67" />
					<circle className="cls-2" cx="26.6" cy="63.46" r="26.6" />
					<circle className="cls-1" cx="85.58" cy="83.32" r="20.14" />
					<circle className="cls-3" cx="445.08" cy="66.47" r="5.54" />
					<circle className="cls-3" cx="254.86" cy="5.49" r="5.49" />
				</svg>
			</Link>
		</div>
	);
}

export default Logo;