import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Agreements.css';

function Agreements() {
	useEffect(() => {
		document.title = "Assisted Innovations supports your team and helps you grow";
		trackPage();
	});
	
	return (
		<>
			<Header>
				<h1>Our Agreements</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div className="step-container step-odd">
						<h2 className="step-title agreement-one">Reactive Support and Maintenance</h2>
						<div className="step-content">
							<p>
								One of the easiest ways to work with us is our Reactive Support and Maintenance Agreement.
							</p>
							<p>
								This agreement gives you an email to send to for help or new requests. Your email creates a ticket in our system, and that ticket is worked to completion. We estimate out time to resolution for all tickets, and will bill you weekly for time approved and worked.
							</p>
							<p>
								We will also periodically ask you to talk strategy, and bring ideas to the table to help improve your processes and application maintainability.
							</p>
							<p>
								The big benefit to this agreement is that it has no financial commitment from your end. There is no required monthly fee.
							</p>
							<p>
								Need some assistance, without the commitment? <Link to="/contact">Reach out</Link>
							</p>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title agreement-two">Proactive Application Management</h2>
						<div className="step-content">
							<p>
								Are you working with an offshore development company and feeling like it is taking too much time to not really get anywhere?
							</p>
							<p>
								This speaks to a problem that happens with offshore companies a lot. They do what they are told. And if you don't have someone internally that is strongly holding the technical direction - you will find yourself not really moving forward.
							</p>
							<p>
								Our Proactive Application Management covers this scenario and more.
							</p>
							<p>
								With Proactive Application Management - our team comes in and lends a hand with the management of your application. We work with you to create and document a strategy, and then work with the team involved to make sure the strategy is being executed.
							</p>
							<p>
								That team can be in-house, offshore, or even our team. Our goal is to help you make sure that the effort is being effective.
							</p>
							<p>
								And to make it so you have a single point of contact - we give you access to our helpdesk system. You can put tickets in and we will interface with the appropriate team in a timely manner to make sure that things are getting done. You can optionally have this part be our team as well.
							</p>
							<p>
								Ready for some help managing your strategic direction? <Link to="/contact">Reach out</Link>
							</p>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title agreement-three">Proactive Application Support and Maintenance</h2>
						<div className="step-content">
							<p>
								This is a great next step to up the ante.
							</p>
							<p>
								Similar to the Reactive Support and Maintenance Agreement - you get an email for ticket submission. The difference is that you pay a flat monthly fee and we don't bill you for the hours spent.
							</p>
							<p>
								The bigger difference is that we increase the proactive strategy. We meet with you monthly to talk strategically and work with a list of initiatives that we are working on outside of the tickets you submit.
							</p>
							<p>
								Ready to get support and strategy? <Link to="/contact">Reach out</Link>
							</p>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title agreement-four">Business Evolution Retainer</h2>
						<div className="step-content">
							<p>
								You may have heard this type of agreement called vCAO, vCIO or even just Strategy.
							</p>
							<p>
								Regardless of the name &rarr; the idea is that you are bringing Assisted Innovations in as a strategic leader in your business. 
							</p>
							<p>
								We always start with a Discovery to form a strategy document. This document becomes our starting point for development.
							</p>
							<p>
								We then dedicate resources to your account to implement the strategic initiatives, and tune that strategy as your company changes. Remembering our approach - to help you get to a point where you no longer need us - we are in constant communication to make sure that we are hitting that goal.
							</p>
							<p>
								Similar to our Proactive Application Support and Maintenance Agreement - you pay a flat monthly fee for this Retainer. The fee includes all development work plus constant strategic discussion.
							</p>
							<p>
								Ready to evolve? <Link to="/contact">Reach out</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Agreements;