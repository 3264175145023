import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import About from './pages/About';
import Agreements from './pages/Agreements';
import Approach from './pages/Approach';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Emergency from './pages/Emergency';
import Post from './pages/Blog/Post';
import Services from './pages/Services';
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Logo from './components/Logo';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';

import './App.css';

function App() {
	let currYear = new Date().getFullYear();

	return (
		<Router>
			<>
				<ScrollToTop />
				<header className="site-header-container">
					<div className="site-header">
						<Logo />
						{/* <PhoneNumber /> */}
						{/* <Navigation /> */}
					</div>
				</header>
				<div className="site-content">
					<Routes>
						<Route path="/about" element={<About />} />
						<Route path="/approach" element={<Approach />} />
						<Route path="/services/agreements" element={<Agreements />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/services" element={<Services />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/emergency" element={<Emergency />} />
						<Route path="/blog" element={<Blog />} />
						<Route path="/blog/*" element={<Post />} />
						<Route path="/" element={<Home />} />
					</Routes>
				</div>
				<div className="site-footer">
					<div className="copyright">&copy; {currYear} Assisted Innovations, LLC</div>
					<Navigation footer="true" />
				</div>
			</>
		</Router>
	);
}

export default App;
