import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function SiteConversionRatePost() {
	useEffect(() => {
		document.title = "Innovation Space | Site Conversion Rate: The Multiplier for Your Efforts";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header site-conversion-rate-post">
				<h1>Site Conversion Rate: The Multiplier for Your Efforts</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							Alright! You have the new eCommerce site live and people are buying things. Your revenue is starting to move – but not fast enough to hit your goals.
						</p>
						<p>
							In this situation – most people look to increase their audience. It is a logical path to go; more people on the site means more people buying things and more revenue.
						</p>
						<p>
							So you look to start a new PPC ad campaign with some marketing budget to get more site visitors.
						</p>
						<p>
							Big success! It works! You get more visitors, which translates into more buyers and more revenue. You even hit your revenue goal for the year.
						</p>
						<p>
							So far so good, right?
						</p>
						<p>
							The problem is you spent $20K in PPC ads to get those added visits. This means that – while you hit your revenue goal – your profitability suffered.
						</p>
						<p>
							But was there another way? Possibly.
						</p>
						<h2>Conversion Rate</h2>
						<p>
							If you aren’t familiar with conversion rate – it is a number noting the percentage of site visitors that convert.
						</p>
						<p>
							So -> (conversions / visits) * 100
						</p>
						<p>
							The standard conversion rate varies depending on the industry – but tends to hang out in the 2% range.
						</p>
						<p>
							When you go back and calculate your site conversion rate – you find that it is at 0.2%. That means you get 2 conversions for every 1000 visits.
						</p>
						<p>
							What does this mean?
						</p>
						<h2>Napkin Math</h2>
						<p>
							Say you earned $100,000 in 6 months with 50K visits and a 0.2% conversion rate, and that you are looking to get to $500,000 for the year.
						</p>
						<p>
							You would need to increase your visits 4x over the next 6 months to hit your goal.
						</p>
						<p>
							Let’s look at that a different way:
						</p>
						<p>
							You got 50,000 visits, and 100 conversions over the first 6 months.
						</p>
						<p>
							To get to $500K for the year, you would need to get 200,000 visits with 400 conversions over the next 6 months.
						</p>
						<h2>Where Does Conversion Rate Come In?</h2>
						<p>
							Instead of focusing on just increasing visits – let’s focus on increasing your conversion rate.
						</p>
						<p>
							You install some tooling to gather data for a few weeks, then spend some development effort to refine the site.
						</p>
						<p>
							With your changes – your conversion rate bumps up to 0.8%. This is still below the average – but is 4x what you had previously.
						</p>
						<p>
							How much traffic do you need to get to hit your goal now?
						</p>
						<p>
							50K visits.
						</p>
						<h2>A Multiplier</h2>
						<p>
							Because conversion rate acts as a multiplier – you don’t need to increase your traffic at all to quadruple your revenue.
						</p>
						<p>
							What do the numbers look like?
						</p>
						<p>
							50K visits at 0.2% means 100 conversions (X / 50,000 = 0.002 &rarr; X = 50,000 * 0.002 &rarr; X = 100)
						</p>
						<p>
							50K visits at 0.8% means 400 conversions (X / 50,000 = 0.008 &rarr; X = 50,000 * 0.008 &rarr; X = 400)
						</p>
						<p>
							Because you increased the conversion rate (IE – the number of people who convert) you get more conversions from the same traffic, and more revenue as a result.
						</p>
						<h2>Scale It Up</h2>
						<p>
							Now that you have increased your conversion rate – let’s add PPC and other visit increasing strategies ot the mix.
						</p>
						<p>
							You spend $20K on a PPC campaign that doubles your visits over the next 6 months.
						</p>
						<p>
							SO: 100K visits at 0.8% means 800 conversions (X / 100,000 = 0.008 &rarr; X = 100,000 * 0.008 &rarr; X = 800)
						</p>
						<p>
							Notice how the number of conversions has now increased 8x.
						</p>
						<p>
							Because you quadrupled the conversion rate – the PPC campaign was wildly successful. You came close to doubling your annual goal! (IE - $100K for first 6 months + $800K for second = $900K total)
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							At Assisted Innovations we want to help you grow no matter what. Sometimes that means that we have conversations about the cost benefit of any path.
						</p>
						<p>
							If you want to run PPC ads, but your site isn’t converting – we will talk to you about spending some time working on conversions before going down that road.
						</p>
						<p>
							Looking to grow your revenue, and think conversion rate optimization or PPC could help? <Link to="/contact">Reach out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default SiteConversionRatePost;