import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Emergency.css';

function Emergency() {
	const [values, setValues] = useState({ name: "", email: "", message: "" });
	const [showConfirmation, setShowConfirmation] = useState(false);

	useEffect(() => {
		document.title = "Contact Our Web and Application Development Agency";
		trackPage();
	}, []);

	const handleInputChange = e => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	}

	const encode = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const handleSubmit = e => {
		e.preventDefault();

		if (values.name !== "" && values.email !== "" && values.message !== "") {
			fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode({ "form-name": "emergency-contact", ...values })
			}).then(() => {
				setValues({ name: "", email: "", message: "" });
				setShowConfirmation(true);
			}).catch(error => console.log(error));
		}
	};

	return (
		<>
			<Header>
				<h1>Contact Assisted Innovations</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						{showConfirmation && <div className="confirmation-message">Thank you for reaching out. We will review your note and be in touch!</div>}
						<form name="emergency-contact" method="POST" onSubmit={handleSubmit} className="contact-form" netlify>
							<div className="input input-half">
								<label htmlFor="name">Name:</label>
								<input type="text" name="name" onChange={handleInputChange} value={values.name} />
							</div>
							<div className="input input-half">
								<label htmlFor="email">Email:</label>
								<input type="email" name="email" onChange={handleInputChange} value={values.email} />
							</div>
							<div className="input input-full">
								<label htmlFor="message">Message:</label>
								<textarea name="message" rows="5" onChange={handleInputChange} value={values.message}></textarea>
							</div>
							<div className="input input-full input-right">
								<button type="submit" name="submit">Send Message</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export default Emergency;