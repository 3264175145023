import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

function About() {
	useEffect(() => {
		document.title = "About Our Web and Application Development Agency";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>About Assisted Innovations</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						<p>
							We spent years working at agencies that continuously failed their customers in the name of profits.
						</p>
						<p>
							And we became sick of it.
						</p>
						<p>
							That is why we started Assisted Innovations. To create a team that is hyper focused on growth. <strong>Everyone's growth</strong>.
						</p>
					</div>
					<div>
						<h2>Everyone's Growth</h2>
						<p>
							Whether you are a customer or employee - we want you to grow. Beyond that - we want you to leave when we can't help you grow any more. We will even help when that time comes.
						</p>
						<p>
							And - when a customer or employee leaves because of growth - we celebrate it. One of our biggest joys is seeing someone grow so much that they no longer need our help.
						</p>
						<p>
							That's right - <strong>we want to see you outgrow us</strong>.
						</p>
						<p>
							Unlike other agencies that work to keep you as a client forever - we want you to grow no matter what.
						</p>
					</div>
					<div>
						<h2>Our experience with growth</h2>
						<p>
							Our team has close to 20 years of experience helping companies change their processes and grow. They have helped companies find <strong>wild success</strong>, go into <strong>hyper growth</strong> and hit <strong>goals they didn't think were possible</strong>.
						</p>
						<p>
							From our background - our leader once helped a company that works with very large clients. They were bringing on 1 new client each year, taking 6 months to onboard each of them, and needing to hire new employees to keep up with the resulting workload.
						</p>
						<p>
							After some process changes - and novel automation - they were able to shorten onboarding to a few hours and started bringing on close to 10 clients each year. They went from 6 clients to 50 over 5 years, without a significant workload or workforce increase.
						</p>
						<p>
							The new processes allowed them to scale 800% without significant workforce increase! Their team started getting more done with less stress.
						</p>
						<p>
							On top of that - <strong>they spent significantly less</strong> than they would have hiring people to do the work the old way.
						</p>
					</div>
					<div>
						<h2>Business Evolution</h2>
						<p>
							We like to call that kind of growth <em>Business Evolution</em>. It means that the company didn't just get better, or grow. Rather - they evolved into a different company. They still offer the same services to their clients - but <strong>how</strong> they do business is forever changed.
						</p>
						<p>
							When it comes to evolution - every company is different. What will you evolve into?
						</p>
						<p>
							Ready to find out? <Link to="/contact">Let's talk</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;