import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function BenefitsPWAPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Benefits of Progressive Web Apps";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header benefits-pwa-post">
				<h1>The Benefits of Progressive Web Apps</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							In 2010 the graphic designer Ethan Marcotte coined the term Responsive Web Design. With this concept he talked about the concept that websites should be built to look good and work on any screen – no matter how large or small.
						</p>
						<p>
							Over the ensuing decade since then this term has become mainstream. It's hard to find a development agency that isn't talking about RWD today.
						</p>
						<p>
							Ten years from now the same will be true of the Progressive Web App.
						</p>
						<p>
							This is a term that you see bandied about a lot in developer circles and on Google. Indeed – developers at Google coined the term in 2015.
						</p>
						<h2>What is a Progressive Web App?</h2>
						<p>
							A Progressive Web App – or PWA – is a web application that has features that make it act like a native mobile app. What are these features?
						</p>
						<h3>Capabilities</h3>
						<p>
							Back when the smart phone was introduced – it was impossible to do many interesting things on the web. The development landscape has changed quite a bit - however. Today, most capabilities that were previously only accessible to native apps are available on the web.
						</p>
						<p>
							This is also continuously evolving – with new APIs being considered to allow the web file system access, full clipboard support and other functionalities that are currently only accessible to native apps.
						</p>
						<h3>Reliability</h3>
						<p>
							Native apps always had an edge on reliability. With an app that is installed on your device you can always open it and use it – even if you have a spotty internet connection. If the app needs to get information from the internet and can't for some reason it will tell you why.
						</p>
						<p>
							The web now has these capabilities. PWAs continue to work when you are offline and can tell you when there is a problem.
						</p>
						<h3>Speed</h3>
						<p>
							Native apps also have historically been more performant. They open immediately, react quickly to interaction, and just generally feel like something that is installed on your device – because they are.
						</p>
						<p>
							PWAs also have an eye on performance. They use various newer web technologies to give you the speed and responsiveness of a native app.
						</p>
						<h3>Installation</h3>
						<p>
							The next consideration when comparing a native app to a web app is that native apps are physically installed on your device. They show an icon on your phone, and open full screen without a browser interface.
						</p>
						<p>
							PWAs can give you this experience as well; in that you can install an icon to your device. When installed – they open without the browser interface. Realistically – once they are installed you might forget that they are web apps.
						</p>
						<h3>Discoverability</h3>
						<p>
							This is the area that native apps still have a small edge on PWAs. Native apps can be found by searching through your platform's app store.
						</p>
						<p>
							As PWAs are web apps, their primary publishing location is the web. They are also not able to be directly listed in the app stores.
						</p>
						<p>
							This is a problem that is being actively worked on – with all platforms except iOS giving developers a way to easily package and submit their PWA to the store.
						</p>
						<h2>Why a Progressive Web App?</h2>
						<p>
							So far, we have discussed what a PWA is, and how they work like native apps. After this discussion, you may still be questioning why you would want to build a PWA. Just what are the benefits?
						</p>
						<h3>Maintainability</h3>
						<p>
							When you build native apps, you are building to each individual platform separately. This means that you have several apps to maintain and upgrade. Adding a new feature means multiple projects.
						</p>
						<p>
							When you build a PWA you are building a single app. When you want to upgrade or add features – you must only update that single app.
						</p>
						<h3>Release Schedule</h3>
						<p>
							Because each platform's app store has a different review process – it can be difficult to schedule new feature releases.
						</p>
						<p>
							For a PWA – you are publishing to the web. Because of this – the new feature is available to all users as soon as it is released, regardless of their platform.
						</p>
						<h3>Development Team</h3>
						<p>
							Each platform uses its own programming language for development of native apps. Because of this – to build a native app for each you need developers that know that language. Most often you require a team of developers for each platform you are building to.
						</p>
						<p>
							On top of that – if you are using external developers – this could mean working with multiple agencies or freelancers that specialize in different platforms.
						</p>
						<p>
							With a PWA you are building to the web. As such – your developers have a wide range of languages, frameworks, libraries, and technologies to choose from. When you are hiring – you can cast a wide net for web developers, and only hire a single team, agency, or freelancer.
						</p>
						<h3>Costs</h3>
						<p>
							This is by far the biggest benefit of a PWA.
						</p>
						<p>
							Because you are building a single app and publishing it to the web – the costs to build, publish and maintain the app are significantly lower than building native apps.
						</p>
						<p>
							This becomes obvious when you look at each benefit noted above. Each of them infers a lower cost:
						</p>
						<ul>
							<li>Lower maintenance needs result in lower maintenance costs.</li>
							<li>Simpler feature releases result in lower marketing costs.</li>
							<li>Simpler feature releases also result in earlier marketing, which can turn into more conversions.</li>
							<li>Hiring a single development team results in lower personnel costs.</li>
						</ul>
						<h2>In Conclusion</h2>
						<p>
							If you do not need functionality that is only available to native apps today, it is to your advantage to build a PWA instead of native apps. The benefits are great and only improving over time.
						</p>
						<p>
							In a few years PWA will be the term that every agency is talking about, and every company will be asking for. It is the natural next step of RWD.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations has been building PWAs since the technologies became widely available.
						</p>
						<p>
							If you are thinking about building a native app, a PWA or a website – <Link to="/contact">reach out and let's talk</Link>.
						</p>
						<p>
							We would love to help you figure out the best way to bring your project to life.
						</p>

					</article>
				</div>
			</div>
		</>
	)
}

export default BenefitsPWAPost;