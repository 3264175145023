import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../../components/Header';
import trackPage from '../../../../../utils/trackPage';

import flexcubeImage from './flexcube-interface-example.png';

function UserExperienceCostsPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Costs of Bad User Experience";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header user-experience-costs-post">
				<h1>The Costs of Bad User Experience</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							When building out any application – the experience that all users have with it is critical to the end result. A poor user experience can lead to frustration with your customers – which translates to less conversions and more support calls.
						</p>
						<p>
							There are a lot of good stories about the costs of poor user experience.
						</p>
						<p>
							For instance – back in 2014 NBC started using the hamburger menu for their desktop navigation. Their users mostly stopped using the navigation altogether. They tried several things – like adding an informational overlay to tell people about the menu and changing its color – but the navigation was not being used. They eventually redesigned their site again to bring back a standard horizontal navigation – because frustrated users mean less users – and less revenue.
						</p>
						<p>
							The thing that isn't talked about very much is the poor user experience of the administration interfaces for these systems – and the costs that are incurred as a result.
						</p>
						<h2>A Dramatic Example</h2>
						<p>
							In 2020 Citibank was acting as an agent for Revlon with their creditors. When they went to make an $8M interest payment they accidentally made a full principal payment of close to $900 Million. They sent out notifications the next day – but some of the creditors refused to return the payment. Citibank recently lost a lawsuit attempting to force the return of the accidentally sent funds. This mistake has likely cost them $500 Million (unless they succeed in appeals to get the funds returned)
						</p>
						<p>
							The reason this mistake happened is because of some extremely poor user experience in their administrative software – called Flexcube. As the judge in the lawsuit – Jesse Furman – noted:
						</p>
						<blockquote>
							On Flexcube, the easiest (or perhaps only) way to execute the transaction — to pay the Angelo Gordon Lenders their share of the principal and interim interest owed as of August 11, 2020, and then to reconstitute the 2016 Term Loan with the remaining Lenders — was to enter it in the system as if paying off the loan in its entirety, thereby triggering accrued interest payments to all Lenders, but to direct the principal portion of the payment to a "wash account" — "an internal Citibank account that shows journal entries . . . used for certain Flexcube transactions to account for internal cashless fund entries and . . . to help ensure that money does not leave the bank."
						</blockquote>
						<p>
							So – One of the most sophisticated banks in the world has software that makes it impossible to send a partial payment to creditors, and they work around this issue by having an internal account set up to receive the principal that is not being paid.
						</p>
						<p>
							The interface looks like this:
						</p>
						<figure>
							<img src={flexcubeImage} alt="Flexcube interface example from court filings" /><figcaption>Image by Jesse Furman</figcaption>
						</figure>
						<p>
							The mistake here was that the people involved in the transfer (a subcontractor, his colleague, and a senior-level Citibank employee) only set up the "Principal" line, when they also needed to enter the wash account in the "Front" and "Fund" lines.
						</p>
						<p>
							This easily fixed mistake may have cost Citibank $500 Million, plus an unknown amount of hours and fees related to the ensuing lawsuit.
						</p>
						<h2>Alternatives</h2>
						<p>
							What could have been done to prevent this outcome?
						</p>
						<p>
							Citibank already had a system in place requiring 3 people to sign off on a transaction of this size. That process could have prevented this - and often will - if the people involved are properly trained on the system and know what to look for.
						</p>		
						<p>
							But that process didn't help here – because all 3 thought the transaction was set-up properly. This kind of process just puts a band-aid on the real issue at hand &rarr; that the software is poorly built.
						</p>
						<p>
							If we want to fix the actual problem we need to tackle the software itself.
						</p>
						<p>
							The biggest change that we could make to prevent this would be to make the system such that you can create the transaction without entering it as if paying off the loan in its entirety. This single change would completely remove the issue, and coupled with the approval process already in place would prevent this kind of mistake altogether.
						</p>
						<p>
							But in case there is a compelling reason why the transaction must be entered this way – then there are several other things that can be done to help:
						</p>
						<ul>
							<li>The system can give a confirmation listing all accounts to receive funds with the amount that will go to each.</li>
							<li>The system can be updated so that the account noted in the "principal" line is where the principal portion goes – regardless of other settings.</li>
							<li>The system could be updated to allow you to enter an account with the amount to go to it – so you can specify exactly how much goes to the "wash account".</li>
						</ul>
						<p>
							Any of these three approaches would significantly improve things. Even just the confirmation showing the list of accounts along with how much money is going to each would have prevented this scenario.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							At Assisted Innovations we are always looking to improve the user experience for all users – regardless of whether they are your customers or your employees.
						</p>
						<p>
							We carefully pick software to match your capabilities – and often safely modify software to fit your processes.
						</p>
						<p>
							When presented with a scenario like the Flexcube interface – we talk about the potential pitfalls of these processes, and ways to prevent them.
						</p>
						<p>
							Interested in finding out how Assisted Innovations can help you prevent mistakes like this? <Link to="/contact">Reach out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default UserExperienceCostsPost;