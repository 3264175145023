import React from 'react';

import './PhoneNumber.css';

function PhoneNumber() {
	return (
		<div className="phone-container">
			<span>Call Us At:</span>
			<a href="tel:+14409412280">440.941.2280</a>
		</div>
	);
}

export default PhoneNumber;