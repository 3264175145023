import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function ITAppsPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Differences Between IT and Applications Teams";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header it-apps-difference-post">
				<h1>The Differences Between IT and Applications Teams</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							The world of IT (Information Technology) and Applications (software, development, programming, etc.) can be very confusing. There are a lot of technical terms and different types of services.
						</p>
						<p>
							Because of this confusion - it is extremely common to see people group Applications into a big generic IT bubble.
						</p>
						<p>
							Further confusing matters - many professionals actually do have some level of skill in both disciplines. This often leads freelancers and agencies to state that they can support things that are outside of their specialty, leading to stress and dissatisfaction for you.
						</p>
						<p>
							So – just what are these two disciplines, and how 
						</p>
						<h2>
							What Is IT?
						</h2>
						<p>
							IT is concerned with hardware and low-level software. IT professionals are the people you turn to when you need to do things like:
						</p>
						<ul>
							<li>Set up a network.</li>
							<li>Replace a server.</li>
							<li>Extend or fix your Wireless.</li>
							<li>Fix problems with your phone system.</li>
							<li>Upgrade Windows on your machines.</li>
							<li>Set up and configure your ERP.</li>
						</ul>
						<p>
							Most of these tasks deal with a specific piece of hardware: like a server, a laptop, or a phone.
						</p>
						<p>
							IT spreads into software when it comes to things like an operating system (Windows, Mac) or an off-the-shelf software that needs a lot of configuration, but no real customization. Software like NetSuite, or a basic SharePoint installation are great examples of the kind of thing that IT professionals would handle. Most of their setup is with configuration in a provided administration section, and they can usually handle some complex setups and permission structures without customization.
						</p>
						<h2>
							What Is Applications?
						</h2>
						<p>
							Applications – on the other hand – is concerned explicitly with the software. Applications engineers are the people you turn to when you need help with things like:
						</p>
						<ul>
							<li>Getting your website back up.</li>
							<li>Integrating your website to your Accounting system.</li>
							<li>Automating your list-building from your web forms.</li>
							<li>Building a custom software to solve a specific issue.</li>
							<li>Customizing third-party software to work for your needs.</li>
							<li>Customizing your ERP.</li>
							<li>Customizing your CRM.</li>
							<li>Setting up and debugging issues on web servers.</li>
						</ul>
						<p>
							All these tasks deal with a specific software, how it is running, or customizing it. As such – Applications engineers are who IT professionals turn to when something is needed beyond basic configuration.
						</p>
						<p>
							That said – Applications engineers tend to have a very deep understanding of the systems they work in and are often able to create complex setups without doing any real customization. When that is impossible – however – they know what is needed to extend the system or build a custom system altogether.
						</p>
						<h2>
							Cross-Discipline Confusion
						</h2>
						<p>
							As you can see – when it comes to software there can be a lot of squishiness in who can handle the task. IT professionals specialize in configuring things, while Applications engineers specialize in customizing them.
						</p>
						<p>
							However – some IT professionals have limited customization knowledge, and Applications engineers can do the configuration.
						</p>
						<p>
							This is confusing stuff!
						</p>
						<h2>
							Assisted Innovations
						</h2>
						<p>
							The team as Assisted Innovations are Applications specialists. We have been doing this for close to 20 years.
						</p>
						<p>
							We want to help you grow, and know that you need the right people and the right tools to make that happen. So - despite the fact that we have picked up some IT skills over the years and can offer some basic level of support on that front - we aren't going to do you the disservice of saying that we can support your network.
						</p>
						<p>
							If you want to learn more or have a customization need – <Link to="/contact">let's talk</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default ITAppsPost;