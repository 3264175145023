import React from 'react';

import './Header.css';

function Header(props) {
	let classes = (props.classes)? props.classes : "page-header";
	return (
		<div className={classes}>
			{props.children}
		</div>
	);
}

export default Header;