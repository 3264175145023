import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function ThoughtBarrierPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Thought Barrier";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header thought-barrier-post">
				<h1>The Thought Barrier</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							In our previous article – <Link to="/blog/offshore-problems">The Problems with Offshore Development</Link> – we introduced the idea of the Thought Barrier, as it relates to offshore development firms.
						</p>
						<p>
							However – this is one of the greatest barriers to when working with any development team; regardless of where they are located. Even if they are in-house.
						</p>
						<p>
							When you look around for why development projects fail – you will inevitably come across many articles about Project Management and Communication. Then you will come across articles about how developers are terrible at estimating time. And then you will read about the perils of scope creep.
						</p>
						<p>
							There is truth in all of these. And all of them can result from a failure with the Thought Barrier.
						</p>
						<h2>What is the Thought Barrier?</h2>
						<p>
							The Thought Barrier is a failing of critical thought stemming from lack of focus, arrogance, or just not caring. That is why so many agencies fail with this critical skill.
						</p>
						<p>
							Let’s explore the two components that make it up.
						</p>
						<h2>1. Customer Focus</h2>
						<p>
							The responsible developer knows that any software has multiple customers. First is the end-user – your customers – but the administrator is just as important.
						</p>
						<p>
							A developer can build you the most sophisticated website in the world that can convert leads like no tomorrow – but if you can’t use and maintain it then it is a failure. Think about that for a minute. If you can’t use the website that was built, then it doesn’t matter how good it could be for your customers.
						</p>
						<p>
							So – your developers need to be focused on building for you. This means making the application maintainable and configurable, as well as making it easy to use for your customers.
						</p>
						<h2>2. Honest Dialog</h2>
						<p>
							Good developers have years of experience working with the software. They know the pitfalls and the benefits. They know the best practices.
						</p>
						<p>
							At times – you may ask for something that would force them to go against best practices, make it more difficult for you, inadvertently activate a dark SEO pattern, or would require a large investment of time and money.
						</p>
						<p>
							When this happens – it is the responsible developers’ responsibility to speak up and tell you the potential ramifications of the request. They should never tell you that it can’t be done.
						</p>
						<p>
							With a few important caveats – Anything you ask for is possible, and to say otherwise is not true.
						</p>
						<p>
							Those caveats can be big. Your ask may require a large team of developers to build something that has never been built before – meaning a large investment of time and money. It could mean that you need to change platforms mid-project because the platform you are currently on would fail with the new feature. Or – on a smaller but no less problematic note – it could prompt Google to penalize your search rankings.
						</p>
						<p>
							These conversations are critical. If your developers are telling you that something can’t be done, or just saying “OK” to everything you ask for then you have a failure at the Thought Barrier.
						</p>
						<h2>Who Fails at the Thought Barrier?</h2>
						<p>
							The two main components of this way of thinking may seem obvious, and simple to manage.
						</p>
						<p>
							Everyone sometimes fails to navigate the Thought Barrier – however. It is extremely difficult to maintain this customer focus and honest dialog over time.
						</p>
						<p>
							Depending on various elements in their lives – or past experiences – a developer may retreat into what we call “yes mode” – where they just say OK or Yes to every request and move forward. When this happens, they won’t warn you about the potential performance or SEO implications of the requested change. They will balk at very large breaking changes – however – giving you the frustrating “that can’t be done”.
						</p>
						<p>
							When a project is improperly estimated – and time is running short – it can be very tempting for them to “just hardcode this one thing” instead of taking the few hours necessary to make it configurable.
						</p>
						<p>
							Beyond that - some agency leaders will put intense pressure on their development teams in the name of profitability. We have seen agency leaders say things like “The customer only cares if it works. They don’t care if the code looks good.”
						</p>
						<p>
							This prompts developers to start focusing on profitability over customer needs – moving to speed development using shortcuts. Or – even worse – they intentionally make the software difficult to maintain so they can increase revenue from support.
						</p>
						<p>
							Take caution when you see any of the following symptoms:
						</p>
						<ul>
							<li>You always get a hard yes or hard no to every request.</li>
							<li>You are the one bringing up maintainability.</li>
							<li>You are never approached with conversation about the potential impacts of a new request.</li>
							<li>You are never approached with ideas for improving the outcome that you wouldn’t have thought of.</li>
							<li>You are presented with big ongoing support contracts that don’t talk about what measures they are taking beyond fixing or changing things you ask for.</li>
						</ul>
						<p>
							All these symptoms come from a failing of the Thought Barrier. Something that should be simple to manage but is so frequently ignored.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations is no stranger to the difficulties of the Thought Barrier. And we will not promise that our team will never fail at this.
						</p>
						<p>
							We focus on this with every client – no matter the size. Our code reviews have an added focus of thought process beyond just the technical correctness that most reviews look for.
						</p>
						<p>
							And – when we do miss – we take efforts to make it right.
						</p>
						<p>
							If you are having problems with your current development team – no matter where they are located – we can review the code and make recommendations. We can bring strategic leadership to the equation and work with your developers to make sure that the Thought Barrier is being navigated properly.
						</p>
						<p>
							Want to learn more? Check out our <Link to="/services">Strategy, Management &amp; Support or Code Review Services</Link>; our various <Link to="/services/agreements">Agreements</Link>; or <Link to="/contact">Reach Out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default ThoughtBarrierPost;