import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Services.css';

function Services() {
	useEffect(() => {
		document.title = "Assisted Innovations technical competencies for creating change";
		trackPage();
	});
	return (
		<>
			<Header>
				<h1>Our Services</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div className="step-container step-odd">
						<h2 className="step-title step-eight">Management &amp; Support</h2>
						<div className="step-content">
							<p>
								As much as we would prefer otherwise - things go wrong sometimes.
							</p>
							<p>
								Our Management offerings go fairly wide. From helping you manage a team of offshore developers to directly helping you keep your site current and secure &rarr; Assisted Innovations is here to make sure that you are getting value from your software instead of the other way around.
							</p>
							<p>
								We have <Link to="/services/agreements">several agreements</Link> at different levels to cover these needs.
							</p>
							<h4>
								Looking to explore your support options? <Link to="/services/agreements">Check out our agreements</Link> or <Link to="/contact">reach out</Link>.
							</h4>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-five">Integrations</h2>
						<div className="step-content">
							<p>
								Every company works with multiple pieces of software. From your website, to a CRM, an ERP, a Permission Marketing tool, a Ticketing system, Chat or other communications channels, Accounting systems, and many others &rarr; this can get quite complex.
							</p>
							<p>
								When this happens you can lose critical touch-points and end up with the same information in multiple places. This inevitably becomes a chicken-and-the-egg type of scenario as information gets out of synch and your team starts wondering which is accurate. You can also end up with very manual processes to make your business run.
							</p>
							<p>
								This is where we bring integrations in. As the critical glue that ties your software together - we use integrations to automate key processes. From keeping information current to removing manual processes &rarr; like moving eCommerce orders into your accounting system or signing people up for your email list.
							</p>
							<p>
								We know the industry-leading tools for this: think Zapier, Integrator.io and IFTTT. And when those won't work - we know how to build a highly reliable custom integration for your needs.
							</p>
							<h4>
								Do you wish your software would work together better? <Link to="/contact">Reach out</Link>
							</h4>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-one">Web Development</h2>
						<div className="step-content">
							<p>
								A website acts as the face of your company on the internet. Customers and prospects use your website to learn about you and often make purchasing decisions based on what they see.
							</p>
							<p>
								Your website also acts as an important channel for your customers to interact with you. You need to make sure that this is being utilized properly so you can help your customers effectively.
							</p>
							<p>
								In terms of our competencies &rarr; Our proficiency is all across the spectrum: WordPress, Drupal, Wix, Squarespace, Custom. We know all of the new fancy libraries (think React or Angular) and buzzword-laden approaches (think Responsive Web Design or Progressive Web Application).
							</p>
							<h4>
								Does your web presence need built or refreshed? <Link to="/contact">Reach out</Link>
							</h4>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-two">eCommerce</h2>
						<div className="step-content">
							<p>
								The internet is an ever-important sales channel for so many businesses. In order to activate and optimize this sales channel, you need an eCommerce website. At Assisted Innovations we know the eCommerce process in and out - from Quote to Order to Fulfillment and RMA. We are familiar with B2B, B2C B2B2C, and even B2P processes.
							</p>
							<p>
								In terms of competencies &rarr; We are proficient in many of the popular tools on this spectrum: Magento, WooCommerce, BigCommerce and Shopify. We have worked with several of the biggest ERP systems (like Prophet21 and Netsuite), and we know other helpful tools like Shipstation, ShipperHQ and Avatax.
							</p>
							<h4>
								Are you looking to start an eCommerce channel, or need yours to work better? <Link to="/contact">Reach out</Link>
							</h4>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Services;