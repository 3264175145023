import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function OffshoreProblemsPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Problems with Offshore Development";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header offshore-problems-post">
				<h1>The Problems with Offshore Development</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							In our global economy - it is not a new thing to hear about companies sending development overseas – to countries like India. The cost benefits of sending development overseas can be significant:
						</p>
						<ul>
							<li>Most of the time you pay $10-25 per hour of development.</li>
							<li>You can get a whole team of developers for the cost of a single person in the US.</li>
							<li>Since the developers work for another company – you save on benefits as well.</li>
							<li>You need less office space, equipment, etc.</li>
						</ul>
						<p>
							That said – less discussed are the problems with overseas development firms.
						</p>
						<h2>
							The Time Barrier
						</h2>
						<p>
							The typical workday in India spans in a range from Midnight to Noon in Eastern US time. This means that the developers are winding down for the day when you are getting started. If you want to have meetings with them – you are typically going to need to be up at 6AM Eastern time to be effective, and that is even earlier if you are on the west coast.
						</p>
						<p>
							Beyond that – you need to wait until the next night if you have a problem and need a developer to fix it.
						</p>
						<h2>
							The Language Barrier
						</h2>
						<p>
							Developers in India typically speak very good English. They must because their typical customer speaks that language.
						</p>
						<p>
							That said – there are a good deal of people that have problems understanding what is being said because of the accent.
						</p>
						<p>
							Mix the time barrier in here and you have a recipe for miscommunication.
						</p>
						<h2>
							The Thought Barrier
						</h2>
						<p>
							The most significant – and least discussed – barrier that we have come across is the thought barrier.
						</p>
						<p>
							The thing that always stands out when working with an overseas development team is that they are really good at doing exactly what you tell them to do.
						</p>
						<p>
							That may sound like a good thing, and it can be.
						</p>
						<p>
							Except for when you don’t have an internal technical team to work on strategy, someone to break the strategy down into tasks that will achieve your goals, or the knowledge to ask for the right things.
						</p>
						<p>
							For example – a client had a website they built out over years with an overseas company before working with us. The website was doing pretty good for them – but they got tired of spending money every month on small tasks.
						</p>
						<p>
							One of these tasks relates to a set of options for configuring their products. These configurations don’t change anything technically on the site – they don’t even change the SKU – they just add notes to the order that denote how to fulfill it. One of the first tasks the customer gave our team was to reword and add some options.
						</p>
						<p>
							Our team found that the options were hardcoded into the template files for the site, and we made the updates. We then proceeded to make additional updates every couple of days.
						</p>
						<p>
							As this progressed – we quickly talked with our client about changing things so that they could update these terms without our help.
						</p>
						<p>
							This is something the offshore company never did. They added the information – but didn’t make it easily editable because they were not instructed to do so. The only way our client would have gotten that outcome is if they had known to ask for it.
						</p>
						<p>
							This might not seem like that big of a deal on the surface. But it speaks to the big issue here. When working with a development company that only does what they are asked it is your responsibility to know what to ask for.
						</p>
						<h2>
							Assisted Innovations 
						</h2>
						<p>
							The team at Assisted Innovations is here to help with these kinds of scenarios. You can hire us to work with the offshore company for you. We discuss strategy and project status with you, then work with your developers to make sure things are getting done properly; with you and your customers in mind. We also do code reviews on everything they are building – making sure that best practices for speed and security are being followed.
						</p>
						<p>
							Suddenly you won’t need to work with developers early in the morning, get features that only work visually, or wonder about the quality of the code.
						</p>
						<p>
							Ready to stop getting up early? <Link to="/contact">Reach Out</Link>
						</p>

					</article>
				</div>
			</div>
		</>
	)
}

export default OffshoreProblemsPost;