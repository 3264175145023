import React, { useEffect } from 'react';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Approach.css';

function Approach() {
	useEffect(() => {
		document.title = "Assisted Innovations makes software work for You";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>The Assisted Innovations Approach</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						<p>
							Our five stage approach is built to find places in your processes that can lead to growing pains. Because we know that something as seemingly simple as <strong>building a new website can have wide ranging effects on your business</strong>.
						</p>
					</div>
				</div>
				<div className="step-containers">
					<div className="step-container step-odd">
						<h2 className="step-title step-review">Stage 1: Review</h2>
						<div className="step-content">
							<p>In the first stage we get to know your team, their tools, and processes.</p>
							<p>We are looking to understand:</p>
							<ul>
								<li>How you currently do business</li>
								<li>What your team's comfort level is with software</li>
								<li>Which current processes are heavily manual</li>
							</ul>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-strategize">Stage 2: Strategize</h2>
						<div className="step-content">
							<p>In the second stage we take the understanding gained from the Review stage and build out a strategic plan.</p>
							<p>The plan details out items like:</p>
							<ul>
								<li>A website strategy</li>
								<li>Tools to enhance, replace or remove</li>
								<li>Processes that can be enhanced or automated</li>
								<li>New tools or processes to resolve issues</li>
							</ul>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-develop">Stage 3: Develop</h2>
						<div className="step-content">
							<p>The third stage is the start of the implementation process, where we build new items.</p>
							<p>We are working on tasks like:</p>
							<ul>
								<li>Building a new website</li>
								<li>Building new custom software</li>
								<li>Updating and creating processes</li>
								<li>Setting up new tools</li>
							</ul>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-integrate">Stage 4: Integrate</h2>
						<div className="step-content">
							<p>Stage four takes the implementation process further and looks to get your tools working together effectively.</p>
							<p>We are working on tasks like:</p>
							<ul>
								<li>Data cleanup</li>
								<li>Configuring standard integrations (email marketing platform, CRM, etc)</li>
								<li>Creating custom integrations</li>
							</ul>
						</div>
					</div>
					<div className="step-container step-odd">
						<h2 className="step-title step-automate">Stage 5: Automate</h2>
						<div className="step-content">
							<p>In stage five we finish the implementation phase by automating as many steps in your processes as possible.</p>
							<p>We finish up with tasks like:</p>
							<ul>
								<li>Add new integrations to automate manual processes</li>
								<li>Polish up new or optimized processes</li>
								<li>Test all processes to make sure they are optimal</li>
								<li>Create new scripts to properly automate processes</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Approach;