import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function ITvsAppsSupportPost() {
	useEffect(() => {
		document.title = "Innovation Space | Your IT Helpdesk Doesn't Cover Everything You Need";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header it-vs-applications-support-post">
				<h1>Your IT Helpdesk Doesn't Cover Everything You Need</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							You just signed up with a new support agency. It is an exciting step for your company – finally you are going to have the support you need!
						</p>
						<p>
							They install remote monitoring tools, rework your entire network, and even rewire your server room (the most organized you have seen that room in years). Everything is going great.
						</p>
						<p>
							Then your site goes down! You put an urgent ticket in. They take a look and tell you that everything on the server looks correct. It must be something with the site code, but that is outside of the scope of your support.
						</p>
						<p>
							Luckily, the developer that built the site is still around and responds to your call. They come in and figure out the problem – getting the site back up. Phew!
						</p>
						<p>
							OK – now that the issue is resolved you want to do a debrief so you can get a better process in place for the next time.
						</p>
						<p>
							First up – why was the agency you just hired not able to support this? You did your research, and they ticked all the boxes. They even said they would support your web server. When you ask them about this, they say that they did support the web server, and that the problem was the website code itself.
						</p>
						<h2>IT Support</h2>
						<p>
							You are signed up with an IT Agency, also known as an MSP in the industry. Their support desk is for IT Support.
						</p>
						<p>
							When you work with this type of agency you pay a monthly fee that is based on the number of users you have, or the number of “endpoints” you have (where an endpoint is a single machine, like a server, computer, tablet or phone). For that fee you get 24/7 monitoring and support.
						</p>
						<p>
							When you have a problem with your computer, you reach out and they fix it. If the monitoring detects a problem with a machine, they will let you know and fix it.
						</p>
						<p>
							Good agencies will also talk to you about a strategy for upgrading and reworking your network to minimize problems later.
						</p>
						<p>
							So far so good – but we must pay attention to the <Link to="/blog/differences-between-it-and-applications-teams">differences between IT and Applications teams</Link>.
						</p>
						<p>
							Since this is an IT team – they are focused primarily on the hardware on your network. The actual physical machines.
						</p>
						<p>
							This is where your issue came in. The problem with your website was in the software.
						</p>
						<h2>Applications Support</h2>
						<p>
							That takes us to Applications Support.
						</p>
						<p>
							Unlike IT Agencies – Applications Agencies aren't concerned with how many users or machines you have. They tend to bill based on hours, as projects, or occasionally with monthly retainers.
						</p>
						<p>
							The thing is – you might not even be aware that this kind of support exists. Many agencies don't advertise it, though they will gladly bill you hourly to fix things if you reach out. Even if they do advertise these plans – most of them are offering you a <Link to="/blog/reactive-vs-proactive-development-support">reactive plan</Link>.
						</p>
						<p>
							Though they can be hard to find – a good Applications support plan will cover a lot of functions that your IT Support doesn't.
						</p>
						<p>
							Things like:
						</p>
						<ul>
							<li>Monitoring your website for an outage and fixing the problem</li>
							<li>Running regular updates on your website</li>
							<li>Testing your website security and fixing problems</li>
							<li>Testing your website performance and making improvements</li>
							<li>Implementing new functionality in your website</li>
						</ul>		
						<h2>Multi-Purpose Agencies</h2>
						<p>
							There are a good number of multi-purpose agencies out there, and you may be able to find a software support plan in the same company that handles your IT or Marketing services.
						</p>
						<p>
							You need to be careful – however. Most of these companies started out by offering the IT or Marketing services and added Applications services later. When that happens, the company often approaches Applications with the same eye as their primary service.
						</p>
						<p>
							We have seen companies start as an IT MSP, then convert themselves over to multi-purpose agencies offering IT, Marketing and Applications services. These companies approach the new services as though they are just like IT. 
						</p>
						<p>
							The thing is – those companies frequently fail to get any traction in Marketing or Applications. Because those disciplines work a bit differently than IT. Security testing software – while similar – is quite a bit different than testing a network. Website performance is a lot more than the speed of the server and network.
						</p>
						<p>
							Some of these companies do get traction and thrive – but be cautious to make sure that they are offering you the <Link to="/blog/reactive-vs-proactive-development-support">kind of applications support you need</Link>.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							At Assisted Innovations we wanted to try and bring a different perspective in. We have taken the ideas that an MSP uses to manage your network and reworked them to fit for Applications.
						</p>
						<p>
							That means – we work similarly to how an MSP does – just as an Applications agency.
						</p>
						<p>
							We focus on strategies for your software that are aimed at helping you grow. We will talk to you about how your software is working and how it could be improved. We monitor and test your software and tell you when there are problems that need fixed.
						</p>
						<p>
							Oh – and we have a helpdesk. If you have a problem that we don't detect, or even a request, you can <Link to="/contact">reach out</Link> 24/7.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default ITvsAppsSupportPost;