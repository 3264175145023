import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function MobileAppDevelopmentPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Ins and Outs of Mobile App Development";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header mobile-development-post">
				<h1>The Ins and Outs of Mobile App Development</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							Over the last 16 years the world changed quite a bit. Mobile phones became smart and then ubiquitous.
						</p>
						<p>
							Now you can't build an online presence without thinking about mobile phones – and we get a lot of questions about building native mobile apps. How much does it cost? Should I hire a freelancer, an agency, or a team?
						</p>
						<p>
							Let's explore these questions:
						</p>
						<h2>How much does it cost to build a native mobile app?</h2>
						<p>
							This is a hard question to answer – because the cost to market can vary widely depending on your needs.
						</p>
						<p>
							A simple app built for iOS and Android natively is typically going to start at $100,000, with more complexities taking you up quite a bit further. Beyond that – you need to consider ongoing expenses for a custom native mobile application. Things like hosting, support, and ongoing maintenance can add a lot of expense to this process. For planning – start at around $5K/month and expect the number to scale up with your app.
						</p>
						<p>
							Because of these cost – many companies have built services for making apps more cost effectively. Services like <a href="https://buildfire.com/" target="_blank" rel="noreferrer noopener nofollow">BuildFire</a>, <a href="https://www.goodbarber.com/" target="_blank" rel="noreferrer noopener nofollow">GoodBarber</a>, and <a href="https://www.appypie.com/app-builder/appmaker" target="_blank" rel="noreferrer noopener nofollow">AppyPie</a>. These companies built the native apps once and then built a service that allows you to manage your content and add pre-built functionality for a monthly fee. You continue paying that monthly fee as long as your app is live.
						</p>
						<p>
							The app services are significantly less expensive than building an app natively on your own. They start at around $20/month and can scale up to several hundred dollars a month. Most of them also offer a service for less than $10K to build your app out for you.
						</p>
						<p>
							With that low cost comes some limitations – but many of our customers can work within them.
						</p>
						<h2>Should I hire a freelancer, an agency, or a team?</h2>
						<p>
							There are instances – however – when you will need to build a custom native application. You might have specific functionality in mind that you can't get from one of the services. Or you need a bit more control over the look and feel than a service can give you. Whatever the reason – you have the capital and want to pursue a custom native application.
						</p>
						<p>
							Each of these routes has benefits and drawbacks:
						</p>
						<h3>Freelancer</h3>
						<p>
							Hiring a freelancer can be a good way to keep costs down. They tend to have lower overhead, so don't charge as much as an agency would.
						</p>
						<p>
							That said – there can be some big downsides to working with freelancers:
						</p>
						<ul>
							<li>Most are generalists – which means they can do a lot of different things at a decent level but might not be specialized in development.</li>
							<li>They are not good at both development and design.</li>
							<li>They are a single person – which will hit you with a longer timeline and less knowledge/experience.</li>
						</ul>
						<p>
							So – hire a freelancer if you don't have a ton of capital to get moving and have access to a designer and strategist. Also make sure that they are a specialist in the type of development you need.
						</p>
						<p>
							Depending on your level of knowledge – you could be better off hiring someone to build the strategy and help you find the right freelancer(s) for the job.
						</p>
						<h3>An Agency</h3>
						<p>
							Hiring an agency will give you those things that a freelancer can't. They have teams of people for doing this – which will mean that they can handle the strategy, the design, and the development. With more heads means they also have more experience under their belts to make sure that you are getting what you need.
						</p>
						<p>
							All those perks come with a much larger price tag – however. And if they are a really good agency then they probably have a backlog – which means you will need to wait your turn.
						</p>
						<p>
							Hire an agency when you need more help than a freelancer can give you and you have the capital to invest in the build.
						</p>
						<h3>A Team</h3>
						<p>
							Hiring a team means that – instead of paying for a project – you are paying for salaries.
						</p>
						<p>
							This route comes with the biggest benefit that you get exactly what you want. You can hire a strategist, a project manager, a designer, and several developers.
						</p>
						<p>
							The downside here is that you have extra costs (like benefits, payroll expenses, taxes, etc.) that you need to pay on top of the build.
						</p>
						<p>
							Hire a team if you are looking to build a business out of your app and have the capital and knowledge to build a good team for the effort.
						</p>
						<p>
							If you want to build a business and don't have the capital or knowledge to build a good team – you have some options as well. You can hire a consultant to help you build a strategy and pick out good hires.
						</p>
						<p>
							Or you can partner with an agency to start, with the expectation that you will gradually hire people over time. Be careful with this path – as many agencies will push hard to keep you as a client forever.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations can help in many ways. Our goal is to help you grow your business – no matter what.
						</p>
						<p>
							This means that we will gladly act as a consultant – helping you build out a strategy and vet out freelancers, other agencies, or potential employees.
						</p>
						<p>
							We can also be that partner for your new business – making the strategy and building the application. The difference with us is that we will still help you find employees as you grow over time.
						</p>
						<p>
							And – even if you don't want to build a business – we can be the agency that builds you an app and supports it.
						</p>
						<p>
							Interested in learning more about how Assisted Innovations can help you make your app? <Link to="/contact">Reach out</Link>.
						</p>

					</article>
				</div>
			</div>
		</>
	)
}

export default MobileAppDevelopmentPost;