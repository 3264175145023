import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ContactForm from '../../components/ContactForm';
import PhoneNumber from '../../components/PhoneNumber';

import trackPage from '../../utils/trackPage';

import './Home.css';
import Banner from './banner.mp4';

function Home() {
	const reviews = [
		{
			name: 'Gavin J',
			quote: 'We needed immediate, professional technical support and within minutes Assisted Innovation began the coverage we needed. Excellent service and communication, deep driving into the root cause of numerous issues inside our large ecommerce store. The insights have proven invaluable.'
		},
		{
			name: 'Kerry S',
			quote: 'Assisted Innovations is incredibly easy to work with. Ross is knowledgeable and provides solid options and recommendations. Work is performed quickly and correctly. Would solidly recommend working with Ross and his team to anyone looking for development work for the web or applications.'
		},
		{
			name: 'Drew B',
			quote: 'Assisted Innovations has truly been a pleasure to work with. They have always looked out for what the best solutions are for my company and not what is best for their company. I will continue working with them in the future.'
		}
	]


	useEffect(() => {
		document.title = "Empowering Your Team through Web and Application Development";
		trackPage();
	});

	return (
		<>
			<header className="home-header">
				<video preload autoPlay muted loop id="banner">
					<source src={Banner} type="video/mp4"></source>
				</video>
				<div className="content">
					<h1>Get Expert Help with Your Website, <strong><em>Fast</em></strong></h1>
					<span>Our team is standing at the ready to support your site.</span>
					<h2>Call Us At:</h2>
					<PhoneNumber />
					<h2>or Reach Out:</h2>
					<ContactForm />
				</div>
			</header>
			<main className="home-content">
				<div className="cta-block light-block">
					<h2>Your Problems, <strong><em>Solved</em></strong></h2>
					<div className="cta-items">
						<p>
							We are a small team that has over 20 years of experience building and managing websites and online stores. Our specialties include:
						</p>
						<ul>
							<li>
								<h3>Management &amp; Support</h3>
								<p>help when you need it</p>
							</li>
							<li>
								<h3>Integrations</h3>
								<p>connect your tools</p>
							</li>
							<li>
								<h3>Web Development</h3>
								<p>developing your web presence</p>
							</li>
							<li>
								<h3>eCommerce</h3>
								<p>start selling online</p>
							</li>
						</ul>
					</div>
					<div className="cta-button"><Link to='/services'>Learn More About Our Services</Link></div>
				</div>
				<div className="cta-block dark-block services-cta">
					<h2>What People Are Saying</h2>
					<div className="cta-items">
						{reviews.map((review, index) => {
							const { name, quote } = review
							const count = index + 1
							return (
								<blockquote key={count}>
									{quote}<br />
									-- {name}
								</blockquote>
							)
						})}
					</div>
					<div className="cta-button"><Link to='/contact'>Get Help Now</Link></div>
				</div>
			</main>
		</>
	)
}

export default Home;