import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function ReactiveProactivePost() {
	useEffect(() => {
		document.title = "Innovation Space | Reactive vs. Proactive Development Support";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header reactive-proactive-post">
				<h1>Reactive vs. Proactive Development Support</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							You just launched a flashy new website! Congratulations! This is a huge step forward for your business.
						</p>
						<p>
							Most companies have this launch moment. A time when everything is looking great and the whole team is excited to find out just what this will do for the business.
						</p>
						<p>
							The new site starts doing its job and your business is growing. Your team is posting and updating content on the site, responding to leads and/or orders. The excitement fades and you fall into the new normal.
						</p>
						<p>
							Then something goes wrong. The site stops working, even goes offline altogether! You reach out to the agency that developed the website, and they quote you an hourly rate to fix the issue. You agree to pay without too much question because the situation is urgent.
						</p>
						<p>
							The development agency gets the issue fixed and your website is back up. Phew!
						</p>
						<p>
							Then it happens again! You pay for a few more hours to get it fixed again.
						</p>
						<p>
							You start to wonder if the development agency you are working with is good. Why is the website they built you having so many problems?
						</p>
						<p>
							What you may not know is that a website - or any software - needs regular maintenance to be effective. Popular CMS systems like WordPress and Magento release regular updates that need to be applied, and other software that your website relies on gets updated as well. Without that regular maintenance your website may start having these kinds of problems.
						</p>
						<p>
							And so - you find yourself in a very common situation. You are paying hourly to have issues fixed as they come up and becoming more and more dissatisfied as a result.
						</p>
						<h2>
							Reactive Development Support
						</h2>
						<p>
							This scenario details what is known as Reactive Development Support. You reach out when there is a problem and pay hourly to have it resolved. You may also hear this called "break/fix" support.
						</p>
						<p>
							This is the primary support method that most agencies offer and is the default support that you receive if you don't explicitly sign up for a proactive support method.
						</p>
						<p>
							The nice thing about this type of support is that you only pay for what you need. If there are no problems with your website in a month – you don't pay anything. This makes it great for companies on a tight budget who want to avoid a guaranteed monthly expense.
						</p>
						<p>
							On the other side of that – you never know exactly what you will pay in a month. Not only does this make it hard to plan your budget. Your development agency is also only allocating resources to your account when you tell them about a problem. Which means they usually don't have developers free to look at and solve the issue immediately – potentially lengthening your downtime and increasing your costs.
						</p>
						<h2>
							Proactive Application Monitoring
						</h2>
						<p>
							Knowing the issues with customer satisfaction that come from Reactive Development Support - some agencies started offering Proactive Application Monitoring.
						</p>
						<p>
							This type of a plan is like a "Reactive-Plus" support plan. Like Reactive you pay only for the time that is spent working on your site - though some agencies may charge you a small fee for the monitoring service itself.
						</p>
						<p>
							The difference with this plan is that the agency will also alert you to problems that need worked on as they come up. You get alerts like "a WordPress update was just released and needs installed" along with a quote for the number of hours.
						</p>
						<p>
							The big benefit to this kind of monitoring is that you get alerts about potential problems before they become urgent. If you spend the time to take care of them as they come up - you are likely to have fewer issues with your site.
						</p>
						<p>
							The downside to this kind of monitoring is that the agency is not actively looking at your site. Agencies implement this kind of monitoring by watching for updates from the third parties that built the tools used on your site. This means that any custom code used in your site is not getting attention until it breaks.
						</p>
						<h2>
							Proactive Application Support
						</h2>
						<p>
							Bringing proactive monitoring forward - some agencies now offer true Proactive Application Support.
						</p>
						<p>
							With this type of plan, you pay a flat monthly fee for the agency to dedicate time and resources to your account throughout the month. This tends to be a big advantage for you - since the agency is continuously working on your site with the intention of preventing large reactive issues.
						</p>
						<p>
							There are a lot more advantages that come from a proactive application support strategy. Things like getting regular communication with your developers, Reactive support being included in the monthly fee, or even knowing that there are resources dedicated to your site so when something does happen it will get taken care of quickly. It is even possible that the agency will know about the problem before you do.
						</p>
						<p>
							These advantages come from a wide array of tasks that proactive teams perform. Some agencies will offer these tasks in various tiers at different price points, but here is a short list of some of the tasks that you get with a proactive team:
						</p>
						<ol>
							<li>Monitoring for updates in third-party tooling, then scheduling and installing those updates.</li>
							<li>Testing the application for security vulnerabilities, on top of reporting them or patching them.</li>
							<li>Testing the application for performance issues and resolving them.</li>
							<li>Testing the application for user bugs and resolving them.</li>
							<li>Meeting with you to discuss the effectiveness of the application, then developing strategies or new features to tune things.</li>
						</ol>
						<p>
							Beyond the big advantages noted above, the regular monthly cost helps you plan your budget and gets rid of large surprise bills - making this ideal for companies that can plan their budget and don't have other resources for handling these types of tasks.
						</p>
						<h2>
							What kind of support do I need?
						</h2>
						<p>
							Going back to the scenario we started with &rarr; you are stuck in a cycle where your website keeps going down and you pay an agency hourly to fix the problem.
						</p>
						<p>
							Let's tweak the beginning of that scenario just a touch: You just launched a flashy new website! Congratulations! And you signed on with your development agency to do Proactive Application Support!
						</p>
						<p>
							Suddenly - the whole scenario is different. The agency is catching update needs and performing the regular maintenance that the site needs. You may still have a site outage at some point (nothing is perfect) - but the agency jumps on the case and fixes it without extra costs! Beyond that - you find that there are a couple of new features that would make your team's lives easier now that they are in there updating content every day. Those features get added in without extra spend.
						</p>
						<p>
							Ultimately - I will always recommend that the proactive tasks are happening on any site. It helps you stay current, lowers your risk of costly downtime, and helps you get things fixed quickly when downtime does happen.
						</p>
						<p>
							That said - if you have internal resources that can handle the proactive maintenance with just a little support, then stick with a reactive plan.
						</p>
						<p>
							If you don't have those kinds of resources - or if they need more than a little help - then look for a proactive plan. You will save money and have less issues long-term.
						</p>
						<p>
							At Assisted Innovations, we don't offer a purely reactive plan. Our lowest tier agreement - with no monthly cost - includes a basic level of proactive monitoring and quarterly strategic discussion. Our top tier plan is fully comprehensive and includes reactive support. Want to learn more? Check out our <Link to="/services/agreements">Agreements page</Link> for more details, or <Link to="/contact">reach out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default ReactiveProactivePost;