import React, { useEffect } from 'react';

import Header from '../../components/Header';
import ContactForm from '../../components/ContactForm';

import trackPage from '../../utils/trackPage';

import './Contact.css';

function Contact() {
	useEffect(() => {
		document.title = "Contact Our Web and Application Development Agency";
		trackPage();
	}, []);

	return (
		<>
			<Header>
				<h1>Contact Assisted Innovations</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<div>
						<p>
							The Assisted Innovations team primarily works out of the greater Cleveland area. The majority of our customers are dispersed throughout the United States - though we serve customers all over the world.
						</p>
						<p>
							Our team is fully remote, with no physical office. We do this intentionally, as we believe there is great value in being able to work where you are.
						</p>
						<p>
							If you want to explore how the Assisted Innovations team can help you with your problems - reach out with the form below.
						</p>
					</div>
					<ContactForm />
				</div>
			</div>
		</>
	);
}

export default Contact;