import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function BenefitsDiscoveryPost() {
	useEffect(() => {
		document.title = "Innovation Space | The Benefits of a Paid Discovery";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header benefits-discovery-post">
				<h1>The Benefits of a Paid Discovery</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							You may see a ton of information online about why a discovery is great for an agency (hint: agencies absolutely love when they can sell a paid discovery) – but why would you want to sign up for one?
						</p>
						<p>
							Realistically – a paid discovery can feel like a weird kind of project when you are looking to have a website or app built. The deliverable you are paying for is information, most often in the form of some documentation and recommendations. It may feel like the work you need to get done is just getting delayed.
						</p>
						<p>
							So just what is a discovery getting you?
						</p>
						<h2>What is a discovery?</h2>
						<p>
							According to dictionary.com: Discovery is <a href="https://www.dictionary.com/browse/discovery?s=t" target="_blank" rel="noreferrer noopener nofollow">the act or an instance of discovering</a>. To discover is <a href="https://www.dictionary.com/browse/discover?s=t" target="_blank" rel="noreferrer noopener nofollow">to see, get knowledge of, learn of, find, or find out; gain sight or knowledge of (something previously unseen or unknown)</a>.
						</p>
						<p>
							So – discovery is the act of gaining new knowledge.
						</p>
						<p>
							When it comes to software agencies – a discovery is a process of learning about your company’s needs and wants. That knowledge is then used to create a strategy for the project, along with options for realizing that strategy.
						</p>
						<p>
							This is a necessary step to building any application – and it is the first thing that a good agency does when they start a project with you.
						</p>
						<h2>Benefits of paying for a discovery.</h2>
						<p>
							If a discovery is necessary, and done as a part of a project – why would you do this step as a separate project at all?
						</p>
						<h3>Comfort Level</h3>
						<p>
							Choosing an agency for your application build can be difficult. How do you know that they will do a good job? How can you be assured that the amazing sites they reference on their website were truly successful? How do you know that they haven’t had some employee churn and no longer have the talented developers that built those websites on staff?
						</p>
						<p>
							The short answer is – you don’t. When you hire an agency, you are taking a leap of faith. Yes – it is an educated leap, informed through an interview and proposal process – but it is still a leap of faith.
						</p>
						<p>
							Starting with the discovery – as a paid separate engagement – gives you a low-cost way to see how they work. If you don’t like how they do things in practice – you can move on after the discovery without as much lost. Best-case you may have still moved forward, because now you have some documentation that can change how you approach other agencies.
						</p>
						<h3>Uncover unknown paths</h3>
						<p>
							In most cases – you are reaching out to hire an agency because you don’t have the in-house expertise to do the project yourself. Without that in-house expertise you may be unaware of trends or technologies that could be well suited to your goals.
						</p>
						<p>
							One of the biggest trends in the industry right now is <Link to="/blog/the-ins-and-outs-of-mobile-app-development">mobile app development</Link>. Because of this – people will often look to build an iPhone app – and approach agencies to do just that.
						</p>
						<p>
							If the right questions are asked – the agency may uncover that your audience uses Android phones more than iOS or would benefit from having access to the app on their laptops. In these cases - you may be better served building a <Link to="/blog/benefits-of-pwa">Progressive Web App</Link> instead of just building to iOS.
						</p>
						<p>
							Worst-case scenario – you could spend a lot of time and money building something that isn’t going to work for your audience and needs.
						</p>
						<p>
							When you do a paid discovery – the entire purpose of the project is to figure out the best path forward. At the end of the discovery the agency may tell you that what you originally asked for isn’t the direction that they would recommend for meeting your goals. They may recommend another direction entirely – sometimes a direction that you didn’t even know was a possibility.
						</p>
						<h3>Limit scope changes</h3>
						<p>
							A good discovery will give the agency a lot of information to make recommendations. It will also uncover needs that weren’t discussed during the sales process.
						</p>
						<p>
							For a basic example – it might not have been discussed that you need to integrate the forms on your site to a CRM or email marketing tool. Or you may have expected that connecting your orders to your accounting system would be a part of the project.
						</p>
						<p>
							When these kinds of things are uncovered during a website project – the agency will note them as “out of scope” and present you with an estimate to add them in. That estimate could be just a timeline change – but often also includes an additional fee.
						</p>
						<p>
							When you are doing a paid discovery – anything uncovered will be included in the recommendations and options for moving forward. You then have a more complete picture of the project costs and timeline.
						</p>
						<h2>In Conclusion</h2>
						<p>
							From learning more about the agency before making a large investment, to gaining crucial insight and recommendations, or even just to save yourself some hassle and unexpected costs down the line – there are many advantages to doing a paid discovery with an agency.
						</p>
						<p>
							Consider asking for a paid discovery to verify that what you are thinking is the right direction for your goals. This will help you know that you are going the right direction, while also introducing you to the processes of the agency you are working with. You may even find out about a new trend through the process.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations has close to 20 years of experience building successful websites and applications. We stay on top of the trends so that you don’t have to and are always looking for ways to make sure that your goals are met – even if that means recommending a different path.
						</p>
						<p>
							We have seen many projects that failed because what the customer purchased was not what they needed. We have even tried to rework projects inside of the scope constraint to better fit the end goal.
						</p>
						<p>
							We know that you can’t meet your goals without a plan to do so. The plan is so important to us that we often recommend a paid discovery as a part of the process, and always spend the time to get to know your team well before moving forward on a project.
						</p>
						<p>
							If you are looking to make an idea reality, <Link to="/contact">reach out</Link> and see if we can help you plan a path forward.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default BenefitsDiscoveryPost;