import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';

import trackPage from '../../utils/trackPage';

import './Blog.css';

function Blog() {
	useEffect(() => {
		document.title = "Innovation Space | The Assisted Innovations Blog";
		trackPage();
	});

	return (
		<>
			<Header>
				<h1>Innovation Space</h1>
			</Header>
			<div className="page-body">
				<div className="step-containers">
					<article>
						<Link to="/blog/site-conversion-rate" className="article-header-link">
							<div className="article-header site-conversion-rate-post">
								<h1>Site Conversion Rate: The Multiplier for Your Efforts</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								Alright! You have the new eCommerce site live and people are buying things. Your revenue is starting to move – but not fast enough to hit your goals.
							</p>
							<p>
								In this situation – most people look to increase their audience. It is a logical path to go; more people on the site means more people buying things and more revenue.
							</p>
							<p>
								So you look to start a new PPC ad campaign with some marketing budget to get more site visitors.
							</p>
							<p>
								Big success! It works! You get more visitors, which translates into more buyers and more revenue. You even hit your revenue goal for the year.
							</p>
							<p>
								So far so good, right?
							</p>
							<p>
								The problem is you spent $20K in PPC ads to get those added visits. This means that – while you hit your revenue goal – your profitability suffered.
							</p>
							<p>
								But was there another way? Possibly.
							</p>
							<Link to="/blog/site-conversion-rate">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/user-experience-costs" className="article-header-link">
							<div className="article-header user-experience-costs-post">
								<h1>The Costs of Bad User Experience</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								When building out any application – the experience that all users have with it is critical to the end result. A poor user experience can lead to frustration with your customers – which translates to less conversions and more support calls.
							</p>
							<p>
								There are a lot of good stories about the costs of poor user experience.
							</p>
							<p>
								For instance – back in 2014 NBC started using the hamburger menu for their desktop navigation. Their users mostly stopped using the navigation altogether. They tried several things – like adding an informational overlay to tell people about the menu and changing its color – but the navigation was not being used. They eventually redesigned their site again to bring back a standard horizontal navigation – because frustrated users mean less users – and less revenue.
							</p>
							<p>
								The thing that isn’t talked about very much is the poor user experience of the administration interfaces for these systems – and the costs that are incurred as a result.
							</p>
							<Link to="/blog/user-experience-costs">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/benefits-of-discovery" className="article-header-link">
							<div className="article-header benefits-discovery-post">
								<h1>The Benefits of a Paid Discovery</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								You may see a ton of information online about why a discovery is great for an agency (hint: agencies absolutely love when they can sell a paid discovery) – but why would you want to sign up for one?
							</p>
							<p>
								Realistically – a paid discovery can feel like a weird kind of project when you are looking to have a website or app built. The deliverable you are paying for is information, most often in the form of some documentation and recommendations. It may feel like the work you need to get done is just getting delayed.
							</p>
							<p>
								So just what is a discovery getting you?
							</p>
							<Link to="/blog/benefits-of-discovery">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/benefits-of-pwa" className="article-header-link">
							<div className="article-header benefits-pwa-post">
								<h1>The Benefits of Progressive Web Apps</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								In 2010 the graphic designer Ethan Marcotte coined the term Responsive Web Design. With this concept he talked about the concept that websites should be built to look good and work on any screen – no matter how large or small.
							</p>
							<p>
								Over the ensuing decade since then this term has become mainstream. It's hard to find a development agency that isn't talking about RWD today.
							</p>
							<p>
								Ten years from now the same will be true of the Progressive Web App.
							</p>
							<p>
								This is a term that you see bandied about a lot in developer circles and on Google. Indeed – developers at Google coined the term in 2015.
							</p>
							<Link to="/blog/benefits-of-pwa">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/should-build-mobile-app" className="article-header-link">
							<div className="article-header should-build-mobile-app-post">
								<h1>Should You Build a Native Mobile App?</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								Our recent article – <Link to="/blog/the-ins-and-outs-of-mobile-app-development">The Ins and Outs of Mobile App Development</Link> – explored some common questions we hear from prospects thinking about building an app.
							</p>
							<p>
								But the bigger – and often better – question is: Should I even build a native mobile app at all?
							</p>
							<p>
								We are often approached with questions like cost and approach by prospects with ideas for native mobile apps. Through conversation we often find that a Progressive Web App would be a better value for their need.
							</p>
							<p>
								What are the questions that we explore to determine the proper path forward?
							</p>
							<Link to="/blog/should-build-mobile-app">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/the-ins-and-outs-of-mobile-app-development" className="article-header-link">
							<div className="article-header mobile-development-post">
								<h1>The Ins and Outs of Mobile App Development</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								Over the last 16 years the world changed quite a bit. Mobile phones became smart and then ubiquitous.
							</p>
							<p>
								Now you can't build an online presence without thinking about mobile phones – and we get a lot of questions about building native mobile apps. How much does it cost? Should I hire a freelancer, an agency, or a team?
							</p>
							<p>
								Let's explore these questions
							</p>
							<Link to="/blog/the-ins-and-outs-of-mobile-app-development">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/it-vs-applications-support" className="article-header-link">
							<div className="article-header it-vs-applications-support-post">
								<h1>Your IT Helpdesk Doesn't Cover Everything You Need</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								You just signed up with a new support agency. It is an exciting step for your company – finally you are going to have the support you need!
							</p>
							<p>
								They install remote monitoring tools, rework your entire network, and even rewire your server room (the most organized you have seen that room in years). Everything is going great.
							</p>
							<p>
								Then your site goes down! You put an urgent ticket in. They take a look and tell you that everything on the server looks correct. It must be something with the site code, but that is outside of the scope of your support.
							</p>
							<Link to="/blog/it-vs-applications-support">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/why-application-support" className="article-header-link">
							<div className="article-header why-support-post">
								<h1>What is an Application Support Agreement For?</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								It has been a long road, but the new website is live! After getting it live the agency you were working with reminds you of the ongoing support agreement that you haven't signed on for yet.
							</p>		
							<p>
								The agreement has all sorts of terms in it that you don't really understand, but when you try to piece it together it sounds like "pay me a monthly fee, then if something goes wrong you can open a ticket and pay this hourly rate to get it fixed." It might note a certain number of hours in a month that are included before you pay hourly – but the gist of is the same. You pay a monthly retainer, and they help you when something goes wrong.
							</p>
							<p>
								"But what can go wrong" you think to yourself. "If they did a good job then it should just work!"
							</p>
							<p>
								So, you decline the agreement.
							</p>
							<Link to="/blog/why-application-support">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/the-thought-barrier" className="article-header-link">
							<div className="article-header thought-barrier-post">
								<h1>The Thought Barrier</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								When you look around for why development projects fail – you will inevitably come across many articles about Project Management and Communication. Then you will come across articles about how developers are terrible at estimating time. And then you will read about the perils of scope creep.
							</p>		
							<p>
								There is truth in all of these. And all of them can result from a failure with the Thought Barrier.
							</p>
							<Link to="/blog/the-thought-barrier">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/offshore-problems" className="article-header-link">
							<div className="article-header offshore-problems-post">
								<h1>The Problems with Offshore Development</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								In our global economy - it is not a new thing to hear about companies sending development overseas – to countries like India. The cost benefits of sending development overseas can be significant:
							</p>
							<ul>
								<li>Most of the time you pay $10-25 per hour of development.</li>
								<li>You can get a whole team of developers for the cost of a single person in the US.</li>
								<li>Since the developers work for another company – you save on benefits as well.</li>
								<li>You need less office space, equipment, etc.</li>
							</ul>
							<p>
								That said – less discussed are the problems with overseas development firms.
							</p>

							<Link to="/blog/offshore-problems">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/reactive-vs-proactive-development-support" className="article-header-link">
							<div className="article-header reactive-proactive-post">
								<h1>Reactive vs. Proactive Development Support</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								You just launched a flashy new website! Congratulations! This is a huge step forward for your business.
							</p>
							<p>
								Most companies have this launch moment. A time when everything is looking great and the whole team is excited to find out just what this will do for the business.
							</p>
							<p>
								The new site starts doing its job and your business is growing. Your team is posting and updating content on the site, responding to leads and/or orders. The excitement fades and you fall into the new normal.
							</p>		
							<p>
								Then something goes wrong. The site stops working, even goes offline altogether! You reach out to the agency that developed the website, and they quote you an hourly rate to fix the issue. You agree to pay without too much question because the situation is urgent.
							</p>
							<Link to="/blog/reactive-vs-proactive-development-support">[ Read more ]</Link>
						</div>
					</article>
					<article>
						<Link to="/blog/differences-between-it-and-applications-teams" className="article-header-link">
							<div className="article-header it-apps-difference-post">
								<h1>The Differences Between IT and Applications Teams</h1>
							</div>
						</Link>
						<div className="article-excerpt">
							<p>
								The world of IT (Information Technology) and Applications (software, development, programming, etc.) can be very confusing. There are a lot of technical terms and different types of services.
							</p>
							<p>
								Because of this confusion - it is extremely common to see people group Applications into a big generic IT bubble.
							</p>		
							<p>
								While not quite accurate – it is not truly inaccurate to say that Applications is an IT discipline. This is because the worlds of IT and Applications are quite enmeshed. They need each other to be effective and most professionals find they need to have some level of skill in both disciplines.
							</p>		
							<p>
								So – just what are these two disciplines?
							</p>
							<Link to="/blog/differences-between-it-and-applications-teams">[ Read more ]</Link>
						</div>
					</article>
				</div>
			</div>
		</>
	);
}

export default Blog;