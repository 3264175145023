import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function WhySupportPost() {
	useEffect(() => {
		document.title = "Innovation Space | What is an Application Support Agreement For?";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header why-support-post">
				<h1>What is an Application Support Agreement For?</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							It has been a long road, but the new website is live! After getting it live the agency you were working with reminds you of the ongoing support agreement that you haven’t signed on for yet.
						</p>
						<p>
							The agreement has all sorts of terms in it that you don’t really understand, but when you try to piece it together it sounds like "pay me a monthly fee, then if something goes wrong you can open a ticket and pay this hourly rate to get it fixed." It might note a certain number of hours in a month that are included before you pay hourly – but the gist of is the same. You pay a monthly retainer, and they help you when something goes wrong.
						</p>
						<p>
							"But what can go wrong" you think to yourself. "If they did a good job then it should just work!"
						</p>
						<p>
							So, you decline the agreement.
						</p>
						<h2>You Just Bought a Car</h2>
						<p>
							What most agencies don’t explain well is that you effectively just bought a car.
						</p>
						<p>
							When you buy a car – there are a lot of things that you must do to maintain it. You need an oil change every so many miles, to rotate the tires, to wash it, etc. Without these normal maintenance tasks your engine will run poorly and eventually fail, you will get worse gas mileage, and the body will rust. Eventually the car will still break down regardless of the maintenance – but taking these steps help it last a lot longer.
						</p>
						<p>
							Just like the car – a website has normal maintenance tasks that need to be happening for it to continue running well.
						</p>
						<p>
							Say the website is built with a CMS like WordPress or Magento. There are several pieces that regularly update at different intervals: the installed plugins, your theme, and the CMS itself. These updates include bug fixes, enhancements, and often security patches. If you don’t install these updates, then you put your site at risk of being hacked or going offline.
						</p>
						<p>
							These updates are so important that some CMS vendors are moving towards having them automatically install for you.
						</p>
						<h2>More Updates</h2>
						<p>
							That is just the tip of the iceberg when it comes to updates – though.
						</p>
						<p>
							Your site is running on a web server using software that is typically invisible to you. This software executes your site code, builds HTML pages, stores information, runs regular tasks, and allows you to send emails out – among other things.
						</p>
						<p>
							All this software is under active development, and regular updates are issued to patch critical security problems or add enhancements. And they often remove support for versions that are more than a handful of releases back – meaning you won’t get security patches unless you upgrade to the newer version.
						</p>
						<p>
							If your website code isn’t up to date and ready – updating this server software can break your site.
						</p>
						<p>
							Complicating the matter further – depending on your hosting plan you might not get to choose when the server software is updated.
						</p>
						<h2>Custom Code</h2>
						<p>
							As if this wasn’t complex enough – agencies frequently write custom code for certain features. You can avoid this to some degree – but it is often unavoidable to get all the features you need with the look and feel that you want.
						</p>
						<p>
							This custom code needs to be maintained and checked as well. Since it is custom – it will never get updated unless you act.
						</p>
						<p>
							It might use functionality that is removed in a future version of your CMS or a server software. Or there might be a security hole that was not caught or known as a risk during development.
						</p>
						<p>
							The longer this code sits without maintenance the greater your risk when running other updates.
						</p>
						<h2>Stressful</h2>
						<p>
							All those updates and risks are stressful!
						</p>
						<p>
							Going back to the start – you declined the agreement. Now it is eight months later and when customers try to open your site, they see a blank page with text saying it was hacked. As soon as you find out you reach out to the agency that built the site and they bill you hourly to investigate.
						</p>
						<p>
							After resolving the problem and getting your website back to what it should look like – they talk to you about the number of updates that will need to be performed, on top of changing all your passwords. Because it has been so long – the updates are risky. You opt to pay them as a project to get things updated so you can try and prevent this issue from happening again.
						</p>
						<p>
							Could this have been prevented if you had signed the agreement months ago?
						</p>
						<p>
							In full honestly – it depends.
						</p>
						<h2>Application Support Agreements</h2>
						<p>
							This type of agreement exists so that you can get the regular maintenance that your website needs to run well. With a good agreement you get more than just the updates – the agency will actively test the site and patch issues.
						</p>
						<p>
							That said – not all agencies approach these the same. Some are adding them on to improve their bottom line without doing anything proactive. They will do the updates if you ask them to – but the responsibility of knowing they are necessary and available still falls on you.
						</p>
						<p>
							Other agencies do the proactive monitoring and schedule the updates without prompting, but don’t test or tweak their custom code unless an issue comes up from an update.
						</p>
						<p>
							And some agencies offer agreements where they really dig in and proactively test the site, beyond basic monitoring. They may run penetration tests to make sure the site is secure while actively testing and updating their custom code.
						</p>
						<p>
							For more details on these different kinds of agreements – check out our post <Link to="/blog/reactive-vs-proactive-development-support">Reactive vs. Proactive Application Support</Link>.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							The team at Assisted Innovations is always looking for ways to help you grow your business. A proper proactive maintenance plan for your website – or other software – is a part of that.
						</p>
						<p>
							We offer plans that span the range noted above – from mostly reactive (with some proactive elements) to a full-service top tier offering that includes penetration testing and constant development. Check out our <Link to="/services/agreements">Agreements</Link> page for more details on those.
						</p>
						<p>
							If you are interested in learning more about how Assisted Innovations can help support your website or application – <Link to="/contact">reach out</Link>.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default WhySupportPost;