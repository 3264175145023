import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../../../components/Header';
import trackPage from '../../../../utils/trackPage';

function ShouldBuildMobileAppPost() {
	useEffect(() => {
		document.title = "Innovation Space | Should You Build a Native Mobile App?";
		trackPage();
	});

	return (
		<>
			<Header classes="article-header should-build-mobile-app-post">
				<h1>Should You Build a Native Mobile App?</h1>
			</Header>
			<div className="page-body post-body">
				<div className="step-containers">
					<article>
						<p>
							Our recent article – <Link to="/blog/the-ins-and-outs-of-mobile-app-development">The Ins and Outs of Mobile App Development</Link> – explored some common questions we hear from prospects thinking about building an app.
						</p>
						<p>
							But the bigger – and often better – question is: Should I even build a native mobile app at all?
						</p>
						<p>
							We are often approached with questions like cost and approach by prospects with ideas for native mobile apps. Through conversation we often find that a <Link to="/blog/benefits-of-pwa">Progressive Web App</Link> would be a better value for their need.
						</p>
						<p>
							What are the questions that we explore to determine the proper path forward?
						</p>
						<h2>The Audience</h2>
						<p>
							Your audience is the first thing you need to think about when deciding if you want to build a native mobile app.
						</p>
						<p>
							What is your target market? What platforms do people in that market use? Do they split between Android and iOS, or do they sit primarily in one camp? Do they expect to be able to access the app on the web as well?
						</p>
						<p>
							These are the questions you need to answer to inform your path forward.
						</p>
						<p>
							If the majority (at least 85-90%) of your target market is on iOS – then you are good to start with just an iOS app.
						</p>
						<p>
							If your users are more split, or if they would get value from the web as well as their phone, it is time to start considering a Progressive Web App instead.
						</p>
						<h2>The Costs</h2>
						<p>
							Our <Link to="/blog/the-ins-and-outs-of-mobile-app-development">recent article</Link> covered this in a bit more detail – but it is a critical question.
						</p>
						<p>
							Do you have the budget to build to 2-3 platforms separately? Do you even have the budget to build to one of them? Are you building internally or hiring an agency? If you are building internally – what is your runway? Do you have the time and budget to build out multiple apps?
						</p>
						<p>
							While there are frameworks and libraries that developers use to simplify the process of building to multiple platforms with the same code-base; it is still significantly less expensive to build to the web. If your budget is limited – consider a Progressive Web App.
						</p>
						<h2>The Functionality</h2>
						<p>
							Last up is a determination on the type of functionality you need.
						</p>
						<p>
							Are you looking to use the phone sensors? Do you need to layer information on top of the camera? Are you building an augmented reality application? Are you building a video game?
						</p>
						<p>
							Alternatively – are you looking to build an app that just displays some informational pages? Do you want a map with some points on it? Are you building a social network?
						</p>
						<p>
							The first set of questions are referring to some functions that would require a native mobile application. With these you need access to the phone sensors and APIs. The second set of questions is talking about functionality that you can easily build in the web.
						</p>
						<p>
							This set of questions is the biggest determining factor. If you are building something that requires a native mobile app – then you need to go that way. If your audience is best served by both major mobile platforms and you don’t have the budget/runway to make them happen – then you need to adjust some plans.
						</p>
						<p>
							If what you are building doesn’t require the native functionality – however – you would be better served with a Progressive Web App. The benefits of this kind of build heavily outweigh anything a native mobile app can give you.
						</p>
						<h2>Assisted Innovations</h2>
						<p>
							At Assisted Innovations we have the experience necessary to build native mobile applications and Progressive Web Apps. 
						</p>
						<p>
							If you aren’t sure what path to go for – <Link to="/contact">reach out</Link> and we can help you make that determination.
						</p>
					</article>
				</div>
			</div>
		</>
	)
}

export default ShouldBuildMobileAppPost;