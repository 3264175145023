import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './Navigation.css';

function Navigation(props) {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	const navItems = [
		{
			id: 1,
			name: 'Approach',
			link: '/approach',
			children: []
		},
		{
			id: 2,
			name: 'Services',
			link: '/services',
			children: [
				{
					id: 1,
					name: 'Our Services',
					link: '/services'
				},
				{
					id: 2,
					name: 'Agreements',
					link: '/services/agreements'
				}
			]
		},
		{
			id: 3,
			name: 'About',
			link: '/about',
			children: []
		},
		{
			id: 4,
			name: 'Blog',
			link: '/blog',
			children: []
		},
		{
			id: 5,
			name: 'Contact',
			link: '/contact',
			children: []
		},
		{
			id: 6,
			name: 'Privacy Policy',
			link: '/privacy-policy',
			children: []
		}
	]

	const toggleNavigation = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	}

	const closeNavigation = () => {
		setOpen(false);
	}

	const restrictNavigation = (event) => {
		event.stopPropagation();
		event.preventDefault();
	}

	const getIfLinkActive = (link) => {
		if (location.pathname.includes(link)) {
			return 'active';
		}

		return '';
	}

	return (
		<>
			<ul className={(open) ? 'site-navigation active' : 'site-navigation'}>
				{
					navItems.map(({ id, name, link, children }) => (
							<li key={id}>
							{children.length === 0 || props.footer ?
								<NavLink to={link} onClick={closeNavigation}>
									<div className="blue-circle"><i className="fas fa-circle"></i></div>
									<span>{name}</span>
								</NavLink>
								:
								<>
								<a href={link} className={getIfLinkActive(link)} onClick={restrictNavigation}>
									<div className="blue-circle"><i className="fas fa-circle"></i></div>
									<span>{name}</span>
								</a>
								<ul className="sub-menu">
									{children.map(({id, name, link}) => (
										<li key={id}><NavLink to={link} onClick={closeNavigation}>{name}</NavLink></li>
									))}
								</ul>
								</>
							}
							</li>
						)
					)
				}
				
			</ul>
			<div className="toggle-button" onClick={toggleNavigation}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
					<path d="M8 104H440C444.406 104 448 100.406 448 96S444.406 88 440 88H8C3.594 88 0 91.594 0 96S3.594 104 8 104ZM440 248H8C3.594 248 0 251.594 0 256S3.594 264 8 264H440C444.406 264 448 260.406 448 256S444.406 248 440 248ZM440 408H8C3.594 408 0 411.594 0 416S3.594 424 8 424H440C444.406 424 448 420.406 448 416S444.406 408 440 408Z"/>
				</svg>
			</div>
		</>
	)
}

export default Navigation;